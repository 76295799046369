// pages/DashboardPage.js
import React, { useEffect } from 'react';
import QuicksightDashboard from '../../components/quicksight-views/quicksight';
import Header from '../../components/header/header';
import TopMenu from '../../components/top-menu/TopMenu';
import { useOutletContext } from 'react-router-dom';
import store from '../../state/store';
import { useSnapshot } from 'valtio';
import local from '../../locales/en.json';
// import DashboardComponent from '../../components/dashboard/DashboardComponent';

const DashboardPage = () => {
    const context = useOutletContext();
    const HeaderNav = useSnapshot(store.headerNav);
    const { addNav, cleanNav } = HeaderNav;
    const topMenu = local.components.menu.options.find(element => element.label === 'DASHBOARDS');

    useEffect(() => {
        cleanNav();
        addNav("Dashboards / All", "/dashboards-all");
    }, [cleanNav, addNav]);

    return (
        <div className=''>
            {context.section === 'top' ? (
                <div className='d-flex flex-column justify-content-between h-100'>
                    {/* <Header />
                    <TopMenu menu={topMenu ? topMenu : {}} /> */}
                </div>
            ) : (
                <div>
                    <div ></div>
                    {/* <QuicksightDashboard /> */}
                    {/* <DashboardComponent /> */}
                </div>
            )}
        </div>
    );
};

export default DashboardPage;
