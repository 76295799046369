import React from 'react';
import logsState from '../../state/logs/logsState';
import { useSnapshot } from 'valtio';
import store from '../../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

interface PaginationControlsProps {
    currentPage: number;
    totalPages: number;
    totalRows: number;
    onNextPage: () => void;
    onPreviousPage: () => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({ currentPage, totalPages, totalRows, onNextPage, onPreviousPage }) => {
    if (totalRows <= 0) {
        return null;
    }

    return (
        <>
            {/* Render pagination only if there are records and pagination data */}
            {totalRows > 0 && currentPage > 0 && (
                <div className="pagination-info">
                    <span>
                        Records: {totalRows}
                    </span>
                    <span>
                        Page {currentPage > 1 && (
                            <FontAwesomeIcon 
                                className='pointer' 
                                icon={faChevronLeft} 
                                onClick={onPreviousPage} 
                            />
                        )}  
                        {currentPage} / {isNaN(totalPages) || totalPages <= 0 ? 1 : totalPages} 
                        {currentPage < totalPages && (
                            <FontAwesomeIcon
                                className='pointer'
                                icon={faChevronRight}
                                onClick={onNextPage}
                            />
                        )}
                    </span>
                </div>
            )}
        </>
    );
};

export default PaginationControls;
