import { proxy } from 'valtio';
import userService from '../../services/userService';
import sessionState from '../sessionState/sessionState'; 
import { getToken } from '../../utils/auth';  
import listViewStore from '../list/listView';

interface User {
  Username: string;
  email: string | null;
  email_verified: boolean | null;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  enabled: boolean;
  UserStatus: string;
}

interface Group {
  id: string;
  name: string;
}

interface UserAttribute {
  Name: string;
  Value: string;
}

interface RawUser {
  Username: string;
  Attributes: UserAttribute[];
  UserCreateDate: string;
  UserLastModifiedDate: string;
  Enabled: boolean;
  UserStatus: string;
}

interface RawResponse {
  statusCode: number;
  body: {
    body: RawUser[];
  };
}

interface UsersState {
  users: User[];
  groups: Group[];
  loading: boolean;
  error: string | null;
  success: boolean;
  loginMessage: any;
  loadUsers: () => Promise<void>;
  loadGroups: () => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, admin: boolean) => Promise<any>;
  confirmRegistration: (email: string, confirmationCode: string) => Promise<void>;
  whoami: () => Promise<User | null>;
  fetchUserGroups: (email: string) => Promise<Group[] | null>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  disableUser: (email: string) => Promise<void>;
  deleteUser: (email: string) => Promise<void>;
  enableUser: (email: string) => Promise<void>;
  resetUserPassword: (email: string) => Promise<any>;
  processUsers: (response: RawResponse) => User[];
}

const usersState = proxy<UsersState>({
  users: [],
  groups: [],
  loading: false,
  error: null,
  success: false,
  loginMessage: null,

  async loadUsers() {
    usersState.loading = true;
    usersState.error = null;
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;

    try {
      const response = await userService.fetchUsers(token);
      const users =  usersState.processUsers(response)
      usersState.users = users;
      
      const columns = users.length > 0 ? Object.keys(users[0]).map(key => ({
        key: key,
        label: key.toUpperCase()
      })) : [];

      //console.log('Generated columns:', columns);
      listViewStore.setListData(users);
      listViewStore.setVisibleColumns(columns);
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
    } finally {
      usersState.loading = false;
    }
  },

  async loadGroups() {
    usersState.loading = true;
    usersState.error = null;
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;

    try {
      const groups = await userService.fetchGroups(token);
      usersState.groups = groups;
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
    } finally {
      usersState.loading = false;
    }
  },

  async login(email: string, password: string) {
    usersState.loading = true;
    usersState.error = null;
    try {
      const { success, message } = await userService.login(email, password);
      usersState.success = success;
      usersState.loginMessage = message;

      if (success && message.token) {
        sessionState.saveSession(message.token,message.accessToken, message.expiration); 
      }
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
    } finally {
      usersState.loading = false;
    }
  },

  async register(email: string, password: string, admin: boolean) {
    usersState.loading = true;
    usersState.loading =true
    usersState.error = null;
    try {
      const response = await userService.register(email, password, admin);
      return response;
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
      throw error;
    } finally {
      usersState.loading = false;
    }
  },

  async confirmRegistration(email: string, confirmationCode: string) {
    usersState.loading = true;
    usersState.error = null;
    try {
      const response = await userService.confirmRegistration(email, confirmationCode);
      return response; 
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
      throw error; 
    } finally {
      usersState.loading = false;
    }
  },

  async whoami() {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return null;

    try {
      return await userService.whoami(token);
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
      return null;
    }
  },

  async fetchUserGroups(email: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return null;

    try {
      return await userService.fetchUserGroups(token, email);
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
      return null;
    }
  },

  async changePassword(oldPassword: string, newPassword: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;

    try {
      await userService.changePassword(token, oldPassword, newPassword);
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
    }
  },

  async disableUser(email: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;

    try {
      await userService.disableUser(token, email);
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
    }
  },

  async deleteUser(email: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;

    try {
      await userService.deleteUser(token, email);
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
    }
  },

  async enableUser(email: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;

    try {
      await userService.enableUser(token, email);
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
    }
  },

  async resetUserPassword(email: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;

    try {
      const response = await userService.resetUserPassword(token, email);
      return response 
    } catch (error) {
      usersState.error = error instanceof Error ? error.message : String(error);
      throw error;
    }
  },

  processUsers(response: RawResponse) {
    return response.body.body.map(user => {
      const emailAttr = user.Attributes.find(attr => attr.Name === "email");
      const emailVerifiedAttr = user.Attributes.find(attr => attr.Name === "email_verified");
      
      return {
        Username: user.Username,
        email: emailAttr ? emailAttr.Value : null,
        email_verified: emailVerifiedAttr ? emailVerifiedAttr.Value === 'true' : null,
        UserCreateDate: user.UserCreateDate,
        UserLastModifiedDate: user.UserLastModifiedDate,
        enabled: user.Enabled,
        UserStatus: user.UserStatus
      };
    });
  }

});

export default usersState;
