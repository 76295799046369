import config from '../config/configDev';
import store from '../state/store';
import { useSnapshot } from 'valtio';

const urlapi = config.urlAPI;


interface Log {
  [key: string]: any;
}

interface SearchLogsResponse {
  body: {
    records: Log[];
    execution_id: string;
    next_token: string;
    headers: string[];
    total_rows: number;
  };
  statusCode: number;
}

const searchLogs = async (token: string, query: string): Promise<SearchLogsResponse> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }

    const requestBody = {
      query
    };

    const response = await fetch(`${urlapi}/logs/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(requestBody),
    });

    // Check for HTTP errors
    if (!response.ok) {
      const errorData = await response.json();  // Capture error details from the response
      throw new Error(errorData.message || 'Failed to search logs'); // Use specific error message from the API
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error searching logs:', error);
    throw error;
  }
};

const getReportFromExecutionId = async (token: string, executionId: string): Promise<SearchLogsResponse> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }

    const response = await fetch(`${urlapi}/logs/search/${executionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to search logs with execution ID');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error searching logs with execution ID:', error);
    throw error;
  }
};


const searchLogsNextPage = async (token: string, executionId: string, nextToken: string, headers: string[]): Promise<SearchLogsResponse> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }

    const requestBody = {
      execution_id: executionId, // Reuse the execution ID from the initial request
      next_token: nextToken || undefined, // Only send nextToken if it's available
      headers: headers || undefined, // Pass headers if available
    };

    const response = await fetch(`${urlapi}/logs/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch next page of logs');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching next page of logs:', error);
    throw error;
  }
};


export { searchLogs, searchLogsNextPage, getReportFromExecutionId };
