import React from 'react';
import Menu from './main-menu/menu';
import { Navigate, Outlet } from 'react-router-dom';
import { getToken } from '../utils/auth';
import MainSection from './main-section/MainSection';
import { useSnapshot } from 'valtio';
import store from '../state/store';

const Layout: React.FC = () => {
  const HeaderNav = useSnapshot(store.headerNav);
  const { token, expiration, clearSession } = useSnapshot(store.sessionState)
  const haveToken = getToken(token, expiration, clearSession);
  const lastNavItem = HeaderNav.navArray[HeaderNav.navArray.length - 1];
  const isDashboardAll = lastNavItem?.href === '/dashboards-all';

  if (!haveToken) {
    return <Navigate to="/login" />;
  }

  return (
    <div className={`app ${isDashboardAll ? 'landing-background' : ''}`}>
      <div className="side-menu">
        <Menu />
      </div>
      <div className="main-content">
        <MainSection
          topContent={<Outlet context={{ section: 'top' }} />}
          bottomContent={<Outlet context={{ section: 'bottom' }} />}
        />
      </div>
    </div>
  );
};

export default Layout;
