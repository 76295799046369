import React, { useEffect } from 'react';
import userService from '../services/userService';

const Logout: React.FC = () => {
  useEffect(() => {
    userService.logout();
  }, []);

  return null; // No need to render anything, just handle logout
};

export default Logout;