import React, { useEffect, useState } from 'react';
import { CTable, CTableBody, CTableRow, CTableDataCell } from '@coreui/react';
import { useSnapshot } from 'valtio';
import Loading from '../Loading';
import store from '../../state/store';
import tabState from '../../state/tabs/tabState';
import RowDetails from '../table-details/RowDetails';
import FlexForm from '../flex-form/FlexForm';
import { toggleEnabled, deleteRow, matchLogItem, formatDate } from './tableActions';
import './ListTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import useSwalPopups from "../../hooks/useSwal";

interface ListTableProps {
  isAgentView?: boolean;
  useTabState?: boolean;
  listData?: Log[]; // Nuevo prop opcional para los datos de la lista
  visibleColumns?: Column[]; // Nuevo prop opcional para las columnas visibles
}

interface Log {
  [key: string]: any;
}

interface Column {
  key: string;
  label: string;
}

const ListTable: React.FC<ListTableProps> = ({ useTabState = false, listData, visibleColumns }) => {
  const { showConfirm } = useSwalPopups();
  const ListView = useSnapshot(store.listView);
  const TabView = useSnapshot(tabState);
  const FormState = useSnapshot(store.formState);
  const { formType } = FormState;
  const { display } = FormState;
  const activeTab = useTabState ? TabView.tabs.find(tab => tab.id === TabView.activeTabId) : null;

  const [selectedItem, setSelectedItem] = useState<Log | null>(null);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);

  const handleRowClick = (item: Log) => {
    setSelectedItem(item);
  };

  const handleCloseDetails = () => {
    setSelectedItem(null);
  };

  const getValueForCell = (log: Log, key: string) => {
    const value = log[key];
    const dateColumns = ['timestamp', 'requestCreationTime','usercreatedate', 'userlastmodifieddate']; 
    if (value && !isNaN(Date.parse(value)) && dateColumns.includes(key.toLowerCase())) {
        return formatDate(value);
    }
    return value !== undefined && value !== null ? value.toString() : '';
};

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    const sortedData = [...ListView.filteredListData].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setSortConfig({ key, direction });
    store.listView.setListData(sortedData); // Actualizar el estado de la lista después de ordenar
  };

  const getSortIcon = (key: string) => {
    if (!sortConfig || sortConfig.key !== key) return null;
    return sortConfig.direction === 'asc' ? faSortUp : faSortDown;
  };

  /* if (ListView.loading) {
    return <Loading />;
  } */

  const handleCheckboxChange = (log: Log) => {
    const enabledKey = log.hasOwnProperty('enabled') ? 'enabled' : 'Enabled';
    toggleEnabled(log, formType);
    const updatedLog = { ...log, [enabledKey]: !log[enabledKey] };
    store.listView.setListData(
      ListView.filteredListData.map((item) => (matchLogItem(item, log, formType) ? updatedLog : item))
    );
    handleRowClick(updatedLog);
  };

  const handleDeleteRow = (log: Log) => {
    showConfirm().then((result) => {
      if (result) {
        deleteRow(log, formType);
        store.listView.setListData(
          ListView.filteredListData.filter((item) => !matchLogItem(item, log, formType))
        );
      }
    });
  };

  return (
    <div className="list-view-container">
      <div className="table-container">
        <CTable color="dark" hover striped>
          <thead>
            <tr>
              {formType && <th><FontAwesomeIcon icon={faTrash} /></th>}
              {ListView.visibleColumns.map((col) => (
                <th key={col.key} onClick={() => handleSort(col.key)}>
                  <div className="table-headers">
                    {col.label} {sortConfig?.key === col.key && <FontAwesomeIcon icon={getSortIcon(col.key)!} />}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <CTableBody>
            {ListView.filteredListData.map((log, index) => (
              <CTableRow key={index} onClick={() => handleRowClick(log)}>
                {formType && (
                  <CTableDataCell>
                    <FontAwesomeIcon
                      className="delete-row-button"
                      onClick={() => handleDeleteRow(log)}
                      icon={faTimes}
                    />
                  </CTableDataCell>
                )}
                {ListView.visibleColumns.map((col) => (
                  <CTableDataCell key={col.key}>
                    {col.key.toLowerCase() === 'enabled' ? (
                      <input
                        type="checkbox"
                        className="theme-checkbox"
                        checked={Boolean(log[col.key] || log[col.key.charAt(0).toUpperCase() + col.key.slice(1)])}
                        onChange={() => handleCheckboxChange(log)}
                      />
                    ) : (
                      getValueForCell(log, col.key)
                    )}
                  </CTableDataCell>
                ))}
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
      </div>
      {selectedItem && <RowDetails item={selectedItem} onClose={handleCloseDetails} />}
      {display && <FlexForm />}
    </div>
  );
};

export default ListTable;
