import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { CFormInput, CButton, CSpinner } from '@coreui/react';
import { useSnapshot } from 'valtio';
import SQLBuilderModal from './SQLBuilderModal';
import './SqlControls.css';
import store from '../../state/store';
import sqlConfig from '../../config/sqlConfig.json';
import tabState from '../../state/tabs/tabState';
import logsState from '../../state/logs/logsState';
import { CTooltip } from '@coreui/react/dist/esm/components/tooltip/CTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import useSwalPopups from '../../hooks/useSwal';

interface SqlControlsProps {
  onQuerySubmit: (parsedQuery: ParsedQuery) => void;
}

interface ParsedQuery {
  query: string;
}

const SqlControls: React.FC<SqlControlsProps> = ({ onQuerySubmit }) => {
  const { showError } = useSwalPopups();
  const snapshot = useSnapshot(tabState);
  const sessionSnapshot = useSnapshot(store.sessionState);
  const { token } = useSnapshot(store.sessionState);
  const activeTab = snapshot.tabs.find(tab => tab.id === snapshot.activeTabId);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [renamingTabId, setRenamingTabId] = useState<number | null>(null);
  const [newTabName, setNewTabName] = useState<string>('');
  const [localSqlQuery, setLocalSqlQuery] = useState<string>(activeTab?.sqlQuery || '');
  
  useEffect(() => {
    if (activeTab) {
      setLocalSqlQuery(activeTab.sqlQuery || '');
    }
  }, [activeTab]);

  useEffect(() => {
    tabState.loadTabsFromLocalStorage();
  }, []);

  useEffect(() => {
    logsState.clearLogs();

    // if (activeTab && activeTab.results && activeTab.results.length > 0) {
    //   logsState.setLogsFromTab([...activeTab.results], [...activeTab.visibleColumns]);
    // }
  }, [snapshot.activeTabId]);

  const handleSqlQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = e.target.value.replace(/;/g, '');
    setLocalSqlQuery(sanitizedValue); // Actualiza el estado local
  };

  const handleSqlQuerySubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    let query = localSqlQuery.trim();
  
    if (!query.endsWith(';')) {
      query += ';';
    }
  
    const validationResult = validateSqlQuery(query);
    if (validationResult === null) {
      try {
        const token = sessionSnapshot.token;
        if (!token) {
          showError('No authorization token found. Please log in again.');
          return;
        }
        tabState.setLoadingForTab(activeTab?.id!, true);
        if (query) {
          tabState.setQueryForTab(activeTab?.id!, localSqlQuery);
          await executeQueryAsync(activeTab!.id, query, token);
        }
      } catch (error) {
        console.error('Error submitting the query:', error);
        showError('Error submitting the query. Please try again.');
        tabState.setLoadingForTab(activeTab?.id!, false);
      }
    } else {
      showError('Invalid SQL query. Please check your query and try again.');
    }
  };

  const executeQueryAsync = async (tabId: number, query: string, token: string) => {
    try {
      await logsState.loadLogs(token, query, logsState.page_size);
      const logs = logsState.logs;
      const visibleColumns = logs.length > 0 ? Object.keys(logs[0]).map(key => ({
        key: key,
        label: key.toUpperCase(),
      })) : [];

      if (logs.length === 0) {
        showError('No results found for the query.');
      } else {
        tabState.setResultsForTab(tabId, logs, visibleColumns, logsState.executionId || undefined);
        logsState.setLogsFromTab(logs, visibleColumns);
      }
    } catch (error) {
      showError('Error fetching data. Please try again.');
      logsState.clearLogs();
    } finally {
      tabState.setLoadingForTab(tabId, false);
    }
  };

  const validateSqlQuery = (query: string): string | null => {
    const forbiddenPatterns = new RegExp(sqlConfig.forbiddenPatterns, 'i');
    if (forbiddenPatterns.test(query)) {
      return 'invalid';
    }

    const validSelectPattern = /^SELECT\s.+\sFROM\s.+/i;
    if (!validSelectPattern.test(query)) {
      return 'invalid';
    }

    return null;
  };  

  const handleRenameTab = (id: number) => {
    tabState.renameTab(id, newTabName);
    setRenamingTabId(null);
  };
  
  return (
    <>
      <form onSubmit={handleSqlQuerySubmit} className='w-100'>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
          </div>
        </div>

        {/* SQL Input Field and Run Query Button */}
        < div className='query-form-container'>
          <CTooltip content='Query Builder' >
            <CButton
              onClick={() => setIsModalVisible(true)}
              className="sql-query-builder"
            >
              <FontAwesomeIcon className='back-nav' icon={faGears} />
            </CButton>
          </CTooltip>

          <CFormInput
            className="sql-query-input"
            type="text"
            placeholder="Enter SQL query"
            //value={activeTab?.sqlQuery || ''}
            value={localSqlQuery}
            onChange={handleSqlQueryChange}
          />

          <CTooltip content={activeTab?.loading ? 'loading' : 'Run Query'} >
            <CButton
              className={`sql-query-run ${activeTab?.loading ? 'sql-query-run-loading' : ''}`}
              onClick={handleSqlQuerySubmit}
              disabled={activeTab?.loading}
            >
              {activeTab?.loading ? <CSpinner size="sm" /> : '> Run Query'}
            </CButton>
          </CTooltip>
        </div >
        <button type="submit" style={{ display: 'none' }}>Submit</button>
      </form >
      <SQLBuilderModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onQueryChange={(query) => tabState.setQueryForTab(activeTab?.id!, query)}
      />
    </>
  );
};

export default SqlControls;



