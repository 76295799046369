import config from '../config/configDev';

const urlapi = config.urlAPI;

interface Dashboard {
  [key: string]: any;
}

const fetchDashboards = async (token : string): Promise<{ records: Dashboard[] }> => {
  try {
    //const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/statistics/dashboards/c8a52166c924456a091d10f986ad4ee8757aeca0715730142c211474d2d2a712`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch dashboards');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching dashboards:', error);
    throw error;
  }
};

const dashboardService = { fetchDashboards };

export default dashboardService;
