import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Divider, Box, Button, Switch, Grid } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import agentService from '../../services/agent';
import Loading from '../../components/Loading';
import Header from '../../components/header/header';
import store from '../../state/store';
import { useSnapshot } from 'valtio';

const AgentDetailView = () => {
    const { agentId } = useParams();
    const [agent, setAgent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const navigate = useNavigate();
    const context = useOutletContext();
    const { token } = useSnapshot(store.sessionState);

    useEffect(() => {
        const fetchAgent = async () => {
            try {
                const agentData = await agentService.fetchAgentById(token,agentId);
                setAgent(agentData.body);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching agent:', err);
                setLoading(false);
            }
        };

        fetchAgent();
    }, [agentId]);

    const handleToggleEnabled = async () => {
        setUpdating(true);
        try {
            const updatedEnabledState = !agent.enabled;
            const updatedAgent = { ...agent, enabled: updatedEnabledState };
            await agentService.updateAgentEnabledState(token,agentId, updatedAgent);
            setAgent(updatedAgent);
        } catch (err) {
            console.error('Error updating agent state:', err);
        } finally {
            setUpdating(false);
        }
    };

    if (loading) {
        return <Loading marginTop={30} />;
    }

    return (
        <div>
            {context.section === 'top' ? (
                <>
                    <Header />
                </>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Card sx={{ width: '60%', borderRadius: '16px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)' }}>
                        <CardContent>
                            <Typography variant="h4" component="div" sx={{ marginBottom: 3, color: '#FF6504', textAlign: 'center', fontWeight: 'bold' }}>
                                Agent Details
                            </Typography>
                            <Divider sx={{ marginBottom: 3 }} />
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" sx={{ marginBottom: 1, fontWeight: 'bold' }}><strong>Name:</strong> {agent.name}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" sx={{ marginBottom: 1, fontWeight: 'bold' }}><strong>Enabled:</strong> {agent.enabled ? 'Yes' : 'No'}</Typography>
                                    <Switch
                                        checked={agent.enabled}
                                        onChange={handleToggleEnabled}
                                        color="primary"
                                        disabled={updating}
                                        sx={{ marginTop: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" sx={{ marginBottom: 1, fontWeight: 'bold' }}><strong>IP:</strong> {agent.ip}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" sx={{ marginBottom: 1, fontWeight: 'bold' }}><strong>Group:</strong> {agent.group}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" sx={{ marginBottom: 1, fontWeight: 'bold' }}><strong>OS:</strong> {agent.os}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" sx={{ marginBottom: 1, fontWeight: 'bold' }}><strong>Version:</strong> {agent.version}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" sx={{ marginBottom: 1, fontWeight: 'bold' }}><strong>Last Activity:</strong> {agent.last_activity}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" sx={{ marginBottom: 1, fontWeight: 'bold' }}><strong>Last Logged In User:</strong> {agent.last_logged_in_user}</Typography>
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#FF6504',
                                        color: '#ffffff',
                                        '&:hover': { backgroundColor: '#e55d04' },
                                        padding: '8px 16px',
                                        fontWeight: 'bold'
                                    }}
                                    onClick={() => navigate('/agents')}
                                >
                                    Back to Agent List
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            )}
        </div>
    );
};

export default AgentDetailView;
