import config from '../config/configDev';

const urlapi = config.urlAPI;

interface Agent {
  id: string;
  name: string;
  enabled: boolean;
  ip: string;
  group: string;
  os: string;
  version: string;
  last_activity: string;
  last_logged_in_user: string;
  actions: string;
}

const fetchAgents = async (token: string): Promise<{ body: { agents: Agent[] } }> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/agents`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch agents');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching agents:', error);
    throw error;
  }
};

const fetchAgentById = async (token: string, agentId: string): Promise<Agent> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/agents/${agentId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch agent with ID ${agentId}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching agent with ID ${agentId}:`, error);
    throw error;
  }
};

const updateAgentEnabledState = async (token: string, agentId: string, enabled: boolean): Promise<Agent> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/agents/${agentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify({ enabled }),
    });
    if (!response.ok) {
      throw new Error(`Failed to update agent with ID ${agentId}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error updating agent with ID ${agentId}:`, error);
    throw error;
  }
};

const agentService = { fetchAgents, fetchAgentById, updateAgentEnabledState };

export default agentService;
