import { proxy } from 'valtio';
import dashboardService from '../../services/dashboards';
import mock from '../../assets/mocks/logs.json'

interface Dashboard {
    [key: string]: any;
}

interface DashboardsView {
    dashboards: Dashboard[];
    loading: boolean;
    error: string | null;
    loadDashboards: (token:string) => Promise<void>;
    [key: string]: any; // Index signature
}

const dashboardsView = proxy<DashboardsView>({
    dashboards: [],
    loading: false,
    error: null,
    async loadDashboards(token:string) {
        this.loading = true;
        this.error = null;
        try {
            const response = await dashboardService.fetchDashboards(token);
            this.dashboards = /* mock */ response.records;
        } catch (error) {
            if (error instanceof Error) {
                this.error = error.message;
            } else {
                this.error = String(error);
            }
        } finally {
            this.loading = false;
        }
    },
});

export const setData = (key: string, value: any) => {
    dashboardsView[key] = value;
};

export default dashboardsView;
