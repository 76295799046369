import React, { useEffect } from 'react';
import './App.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Landing from './pages/landing-menu/Landing';
import All from './pages/dashboards/All';
import DashboardAgents from './pages/dashboards/DashboardAgents';
import DashboardAzure from './pages/dashboards/DashboardAzure';
import DashboardGithub from './pages/dashboards/DashboardGithub';
import DashboardGCP from './pages/dashboards/DashboardGCP';
import DashboardAWS from './pages/dashboards/DashboardAWS';
import Logs from './pages/logs/Logs';
import Alerts from './pages/Alerts/Alerts';
import Login from './pages/login/Login';
import Agents from './pages/Agents/AgentListView';
import AgentDetails from './pages/Agents/AgentDetailView';
import Integrations from './pages/Integrations/Integrations';
import Notifications from './pages/Notifications/Notifications';
import NotFound from './components/not-found-error/NotFound';
import Users from './pages/Users/UsersManagement';
import PrivateRoute from './components/PrivateRoute';
import Logout from './components/Logout';
import { SidebarProvider } from './context/SidebarContext';
import { Toaster } from 'sonner';

const App: React.FC = () => {  

  return (
    <Router>
      <SidebarProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<PrivateRoute element={<All />} />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/dashboards-all" element={<PrivateRoute element={<All />} />} />
            <Route path="/dashboards-agents" element={<PrivateRoute element={<DashboardAgents />} />} />
            <Route path="/dashboards-azure" element={<PrivateRoute element={<DashboardAzure />} />} />
            <Route path="/dashboards-github" element={<PrivateRoute element={<DashboardGithub />} />} />
            <Route path="/dashboards-gcp" element={<PrivateRoute element={<DashboardGCP />} />} />
            <Route path="/dashboards-aws" element={<PrivateRoute element={<DashboardAWS />} />} />
            <Route path="/logs" element={<PrivateRoute element={<Logs />} />} />
            <Route path="/alerts" element={<PrivateRoute element={<Alerts />} />} />
            <Route path="/users" element={<PrivateRoute element={<Users />} />} />
            <Route path="/agents" element={<Agents />} />
            <Route path="/agents/:agentId" element={<AgentDetails />} />
            <Route path="/integrations" element={<Integrations />} />
            <Route path="/notifications" element={<Notifications />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </SidebarProvider>
      <Toaster position="bottom-right" />
    </Router>
    
  );
};

export default App;
