import React, { useState } from 'react';
// import QuicksightDashboard from '../../components/quicksight-views/quicksight';
import Header from '../../components/header/header'
import TopMenu from '../../components/top-menu/TopMenu';
import { useOutletContext } from 'react-router-dom';
import store from '../../state/store';
import { useSnapshot } from 'valtio';
import local from '../../locales/en.json'



const DashboardGCP = () => {
    const context = useOutletContext();
    const HeaderNav = useSnapshot(store.headerNav)
    const { addNav, cleanNav } = HeaderNav
    const topMenu = local.components.menu.options.find((element) => element > 'DASHBOARDS');

    useState (() =>{
        cleanNav()
        addNav("Dashboards / GCP","/dashboards-gcp")
    })


    return (
        <div>
            {context.section === 'top' ? (
                <div>
                    <Header/>
                    <TopMenu menu={topMenu}/>
                </div>
            ) : (
                <div >                    
                    
                </div>
            )}
        </div>

    );
}
export default DashboardGCP;