import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useSnapshot } from "valtio";
import ListTable from "../../components/table-component/ListTable";
import TopControls from "../../components/top-controls/TopControls";
import Header from "../../components/header/header";
import usersState from "../../state/userState/userState";
import store from "../../state/store";
import Loading from "../../components/Loading";
import listView from '../../state/list/listView'; // Importa el estado original
import PaginationControls from '../../components/table-component/PaginationControls';

interface OutletContext {
    section: string;
}

const Users: React.FC = () => {
    const HeaderNav = useSnapshot(store.headerNav);
    const LayoutState = useSnapshot(store.layoutState);
    const UsersState = useSnapshot(usersState);
    const { token } = useSnapshot(store.sessionState);
    const FormState = useSnapshot(store.formState);
    const { setLine } = LayoutState;
    const { addNav, cleanNav } = HeaderNav;
    const { setFormType } = FormState;
    const context = useOutletContext<OutletContext>();
    const [isLoading, setIsLoading] = useState(false);
    const listViewSnapshot = useSnapshot(store.listView);



    useEffect(() => {
        cleanNav();
        addNav("users", "/users");
        setLine(true, true);
        setIsLoading(true);
        setFormType("user");
        const fetchData = async () => {
            await usersState.loadUsers();
            setIsLoading(false);
        }
        fetchData();

    }, [cleanNav, addNav, setLine]);




    return (
        <div>
            {context.section === "top" ? (
                <div>
                    <Header />
                    <TopControls />
                </div>
            ) : (
                <div>
                    {isLoading ? <Loading marginTop={30} /> : <><PaginationControls
                        currentPage={listViewSnapshot.currentPage}
                        totalPages={listViewSnapshot.totalPages}
                        totalRows={listViewSnapshot.listData.length}
                        onNextPage={() => listView.nextPage()}
                        onPreviousPage={() => listView.previousPage()} /><ListTable /></>}
                </div>
            )}
        </div>
    );
};

export default Users;
