import sqlConfig from '../config/sqlConfig.json';

export const validateSqlQuery = (query: string): string | null => {
  const forbiddenPatterns = new RegExp(sqlConfig.forbiddenPatterns, 'i');
  if (forbiddenPatterns.test(query)) {
    return 'Query contains forbidden commands like DELETE, INSERT, UPDATE, DROP, or dangerous SQL patterns.';
  }
  const validSelectPattern = /^SELECT\s.+\sFROM\s.+/i;
  if (!validSelectPattern.test(query)) {
    return 'Query must start with a valid SELECT statement!';
  }
  return null;  // Query is valid
};

export const addSemicolonIfNeeded = (query: string): string => {
  return query.trim().endsWith(';') ? query : `${query};`;
};

// New helper to handle timestamp-related clauses
export const handleTimestampClause = (condition: string, value1: string, value2: string | null = null): string => {
  if (condition === 'BETWEEN') {
    return `timestamp BETWEEN '${value1}' AND '${value2}'`;
  }
  return `timestamp ${condition} '${value1}'`;
};

export const generateSQLQuery = (
  selectedTable: string,
  selectedColumns: string[],
  selectedAggregateFunctions: string[],
  selectedLogicalOperators: string[],
  aliases: Record<string, string>,
  clauses: any[],
  timestampCondition?: string,
  timestampValue1?: string,
  timestampValue2?: string,
  useDistinct: boolean = false  // New flag for DISTINCT
): string => {
  if (!selectedTable) return '';
  
  const sanitizedTableName = `"${selectedTable}"`;
  let columnsString = selectedColumns.length > 0 ? selectedColumns.join(', ') : '*';

  if (selectedAggregateFunctions.length > 0 && selectedColumns.length > 0) {
    if (selectedColumns.includes('*')) {
      columnsString = selectedAggregateFunctions.map(func => {
        const alias = aliases[func] ? ` AS ${aliases[func]}` : '';
        return `${func}(*)${alias}`;
      }).join(', ');
    } else {
      columnsString = selectedColumns.map(column => selectedAggregateFunctions.map(func => {
        const alias = aliases[func] ? ` AS ${aliases[func]}` : '';
        return `${func}(${column})${alias}`;
      }).join(', ')).join(', ');
    }
  }

  // Adding DISTINCT/UNIQUE clause if requested
  const distinctClause = useDistinct ? 'DISTINCT ' : '';

  let query = `SELECT ${distinctClause}${columnsString} FROM ${sanitizedTableName}`;
  
  // Handle WHERE, GROUP BY, HAVING, etc.
  const whereClauses = clauses.filter(c => c.type === 'WHERE');
  const groupByClauses = clauses.filter(c => c.type === 'GROUP BY');
  const havingClauses = clauses.filter(c => c.type === 'HAVING');
  const orderByClauses = clauses.filter(c => c.type === 'ORDER BY');

  if (whereClauses.length > 0) {
    // Join the clauses with the selected logical operators
    query += ' WHERE ' + whereClauses.map((c, index) => {
      const logicalOperator = selectedLogicalOperators[index] || 'AND'; // Default to 'AND' if no operator provided
      return `${c.column} ${c.operator} '${c.value}' ${index < whereClauses.length - 1 ? logicalOperator : ''}`;
    }).join(' ');
  }

  if (timestampCondition && timestampValue1) {
    const timestampClause = handleTimestampClause(timestampCondition, timestampValue1, timestampValue2 || '');
    query += whereClauses.length > 0 ? ` AND ${timestampClause}` : ` WHERE ${timestampClause}`;
  }

  if (groupByClauses.length > 0) {
    query += ' GROUP BY ' + groupByClauses.map(c => c.column).join(', ');
  }
  if (havingClauses.length > 0) {
    query += ' HAVING ' + havingClauses.map(c => `${c.column} ${c.operator} '${c.value}'`).join(' AND ');
  }
  if (orderByClauses.length > 0) {
    query += ' ORDER BY ' + orderByClauses.map(c => `${c.column} ${c.orderByDirection}`).join(', ');
  }

  const limitClause = clauses.find(c => c.type === 'LIMIT');
  if (limitClause && limitClause.value) {
    query += ` LIMIT ${limitClause.value}`;
  }

  return addSemicolonIfNeeded(query);
};


// Utility functions to retrieve SQL configuration
export const getTablesAndColumns = () => sqlConfig.tables;
export const getAggregateFunctions = () => sqlConfig.aggregateFunctions;
export const getClauseTypes = () => sqlConfig.clauseTypes;
export const getLogicalOperators = () => sqlConfig.logicalOperators;
export const getSqlOperators = () => sqlConfig.sqlOperators;
export const getOrderByOptions = () => sqlConfig.orderByOptions;
