import { proxy } from 'valtio';

interface NavItem {
    tag: string;
    href: string;
}

interface HeaderNav {
    navArray: NavItem[];
    addNav: (label: string, href: string) => void;
    cleanNav: () => void;
    goBack: () => void;
}

const headerNav: HeaderNav = proxy({
    navArray: [{ tag: "HOME", href: "/" }],
    addNav(label, href) {
        if (!headerNav.navArray.some(item => item.tag === label && item.href === href)) {
            headerNav.navArray.push({ tag: label, href });
        }
    },
    cleanNav() {
        headerNav.navArray = [{ tag: "HOME", href: "/" }];
    },
    goBack() {
        headerNav.navArray.pop();
    }
});

export default headerNav;
