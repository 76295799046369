import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from '../utils/auth';
import { useSnapshot } from 'valtio';
import store from '../state/store';

interface PrivateRouteProps {
  element: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const { token, expiration, clearSession } = useSnapshot(store.sessionState)
  return getToken(token, expiration, clearSession) ? element : <Navigate to="/login" />;
};

export default PrivateRoute;