import { proxy } from 'valtio';
import logsState from '../../state/logs/logsState';  // Import logsState to set logs from the tab

interface Tab {
  id: number;
  name: string;
  sqlQuery: string;
  results: any[];
  visibleColumns: { key: string; label: string }[];
  loading: boolean;
  queryExecuted: boolean;
  executionId: string | null;
  records: number;    // New field to store total records for the tab
  pageSize: number;   // New field to store the page size for the tab
  currentPage: number; // New field to store the current page for the tab
  pageTokens: { [key: number]: string | null }; // New field to store page tokens for the tab
  loadedPages: { [key: number]: any[] };
}

interface TabState {
  tabs: Tab[];
  activeTabId: number;
  addTab: () => void;
  closeTab: (id: number) => void;
  setActiveTab: (id: number) => void;
  setQueryForTab: (id: number, query: string) => void;
  setResultsForTab: (id: number, results: any[], visibleColumns: { key: string; label: string }[], executionId?: string) => void;
  setLoadingForTab: (id: number, loading: boolean) => void;
  setRecordsForTab: (id: number, records: number) => void;
  setPageSizeForTab: (id: number, pageSize: number) => void;
  setCurrentPageForTab: (id: number, currentPage: number) => void;
  markQueryAsExecuted: (id: number) => void;
  renameTab: (id: number, newName: string) => void;
  saveTabsToLocalStorage: () => void;
  loadTabsFromLocalStorage: () => void;
}

const tabState = proxy<TabState>({
  tabs: [
    {
      id: 1,
      name: 'Query 1',
      sqlQuery: '',
      results: [],
      visibleColumns: [],
      loading: false,
      executionId: null,
      queryExecuted: false,
      records: 0,         // Initialize total records to 0
      pageSize: 1000,      // Default page size
      currentPage: 1,       // Start at page 1
      pageTokens: {},      // Initialize page tokens
      loadedPages: {}      // Initialize loaded pages
    }
  ],
  activeTabId: 1,

  addTab() {
    if (this.tabs.length >= 10) {
      return
    }
      this.setLoadingForTab(this.activeTabId, false);
    const newId = this.tabs.length ? this.tabs[this.tabs.length - 1].id + 1 : 1;

    this.tabs.push({
      id: newId,
      name: `Query ${newId}`,
      sqlQuery: '',
      results: [],
      visibleColumns: [],
      loading: false,
      queryExecuted: false,
      executionId: '',
      records: 0,          // Initialize total records to 0
      pageSize: 1000,       // Default page size
      currentPage: 1,
      pageTokens: {}, // Initialize page tokens
      loadedPages: {} // Initialize loaded pages        // Start at page 1
    });

    this.activeTabId = newId;

    logsState.clearLogs();
    this.saveTabsToLocalStorage();
  },

  closeTab(id: number) {
    this.tabs = this.tabs.filter(tab => tab.id !== id);

    if (this.tabs.length === 0) {
      // If no tabs are left, create a new one and clear logs
      this.addTab();
      logsState.clearLogs();  // Clear logs when all tabs are closed
    } else if (this.activeTabId === id) {
      // If the active tab was closed, set the first tab as active
      this.activeTabId = this.tabs[0].id;

      // If the new active tab doesn't have query results, clear logs
      const activeTab = this.tabs[0];
      if (!activeTab.queryExecuted) {
        logsState.clearLogs();  // Clear logs if no query executed for the new active tab
      } else {
        // If query was executed, load logs for the new active tab
        logsState.setLogsFromTab(activeTab.results, activeTab.visibleColumns);
        logsState.total_rows = activeTab.records;
        logsState.page_size = activeTab.pageSize;
        logsState.currentPage = activeTab.currentPage;
      }
    }

    this.saveTabsToLocalStorage();
  },

  setActiveTab(id: number) {
    this.setLoadingForTab(id, true);

    this.activeTabId = id;

    const activeTab = this.tabs.find(tab => tab.id === id);

    if (activeTab && activeTab.queryExecuted) {
      if (activeTab.results.length > 0) {
        logsState.setLogsFromTab(
          activeTab.results,
          activeTab.visibleColumns,
          activeTab.pageTokens,    // Restore pageTokens from the active tab
          activeTab.loadedPages     // Restore loadedPages from the active tab
        );

        logsState.total_rows = activeTab.records;
        logsState.page_size = activeTab.pageSize;
        logsState.currentPage = activeTab.currentPage;
        logsState.totalPages = Math.ceil(activeTab.records / activeTab.pageSize);

        // Restore pageTokens in logsState so that nextToken is available
        logsState.pageTokens = { ...activeTab.pageTokens };

      } else {
        console.log("No results found in active tab, skipping log restoration");
      }

      this.setLoadingForTab(id, false);
    } else {
      logsState.clearLogs();
      this.setLoadingForTab(id, false);
    }

    this.saveTabsToLocalStorage();
  }
  ,

  setQueryForTab(id: number, query: string) {
    const tab = this.tabs.find(tab => tab.id === id);
    if (tab) {
      tab.sqlQuery = query;
      tab.queryExecuted = false;
      this.saveTabsToLocalStorage();
    }
  },

  setResultsForTab(
    id: number,
    results: any[],
    visibleColumns: { key: string; label: string }[],
    executionId?: string
  ) {
    const tab = this.tabs.find(tab => tab.id === id);
    if (tab) {
      tab.results = results;
      tab.visibleColumns = visibleColumns;
      tab.queryExecuted = true;
      if (executionId) {
        tab.executionId = executionId;
      }

      // Store page tokens and loaded pages in the tab
      tab.pageTokens = { ...logsState.pageTokens };
      tab.loadedPages = { ...logsState.loadedPages };

      // Pass logs and visibleColumns to logsState for display
      logsState.setLogsFromTab(results, visibleColumns);

      this.saveTabsToLocalStorage();
    }
  },

  setLoadingForTab(id: number, loading: boolean) {
    const tab = this.tabs.find(tab => tab.id === id);
    if (tab) {
      tab.loading = loading;
    }
  },

  setRecordsForTab(id: number, records: number) {
    const tab = this.tabs.find(tab => tab.id === id);
    if (tab) {
      tab.records = records;
      this.saveTabsToLocalStorage();
    }
  },

  setPageSizeForTab(id: number, pageSize: number) {
    const tab = this.tabs.find(tab => tab.id === id);
    if (tab) {
      tab.pageSize = pageSize;
      this.saveTabsToLocalStorage();
    }
  },

  setCurrentPageForTab(id: number, currentPage: number) {
    const tab = this.tabs.find(tab => tab.id === id);
    if (tab) {
      tab.currentPage = currentPage;
      this.saveTabsToLocalStorage();
    }
  },

  markQueryAsExecuted(id: number) {
    const tab = this.tabs.find(tab => tab.id === id);
    if (tab) {
      tab.queryExecuted = true;
      this.saveTabsToLocalStorage();
    }
  },

  renameTab(id: number, newName: string) {
    const tab = this.tabs.find(tab => tab.id === id);
    if (tab) {
      tab.name = newName;
      this.saveTabsToLocalStorage();
    }
  },

  saveTabsToLocalStorage() {
    const savedState = {
      tabs: this.tabs.map(tab => ({
        ...tab,
        executionId: tab.executionId
      })),
      activeTabId: this.activeTabId,
    };
    localStorage.setItem('sqlTabState', JSON.stringify(savedState));
  },

  loadTabsFromLocalStorage() {
    const savedState = localStorage.getItem('sqlTabState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      this.tabs = parsedState.tabs.map((tab: Tab) => ({
        ...tab,
        executionId: tab.executionId || null,
      }));
      this.activeTabId = parsedState.activeTabId || (this.tabs.length > 0 ? this.tabs[0].id : 1);

      if (this.tabs.length === 0) {
        this.addTab();
      }
    }
  },
});

tabState.loadTabsFromLocalStorage();

export default tabState;
