import React, { useEffect, useState } from 'react';
import { CContainer, CForm, CFormInput, CButton, CImage, CSpinner } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/services';
import './login.css';
import Logo from '../../assets/img/logo.png';
import BackgroundTop from '../../assets/img/login-background-top3.png';
import BackgroundBottom from '../../assets/img/login-background-bottom3.png';
import loginData from '../../locales/en.json';
import store from '../../state/store';
import { useSnapshot } from 'valtio';
import eyeOpen from '../../assets/img/Eye-open.svg';
import eyeClose from '../../assets/img/Eye-close.svg'
import useSwalPopups from './../../hooks/useSwal';

const Login = () => {
    const { showError, confirRegistrarion } = useSwalPopups();
    const sessionState = useSnapshot(store.sessionState);
    const { saveSession } = sessionState;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();
    let loginText = loginData.pages.login;

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setLoading(true);
    
        try {
            if (!email || !password) {
                showError(loginText.errors.noImput);
                return;
            }
    
            const response = await login(email, password);
            
            if (!response) {
                throw new Error('No se recibió respuesta del servidor');
            }
    
            if (response.success && 
                response.message?.body?.body?.AuthenticationResult?.IdToken) {
                
                const authResult = response.message.body.body.AuthenticationResult;
                const token = authResult.IdToken;
                const accToken = authResult.AccessToken;
                const expiration = authResult.ExpiresIn;
    
                localStorage.setItem('tokenDev', token);
                localStorage.setItem('accTokenDev', accToken);
                saveSession(token, accToken, expiration);
                navigate('/');
            } else {               
                if (response.message.statusCode === 401) {
                    confirRegistrarion(email)
                } else {
                    showError(loginText.errors.invalidCred);
                }                
            }
        } catch (error) {
            showError(loginText.errors.ErrorLogin);
        } finally {
            setLoading(false);
        }
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className={`c-app flex-column align-items-center ${errorMessage ? 'login-error' : ''}`}>
            <div className='login-container'>
                <div className='login-header'>
                    <div><label>{loginText.loginHeader.label1}</label></div>
                    <div><label>{loginText.loginHeader.label2}</label></div>
                </div>
                <CContainer>
                    <CForm className="text-center" onSubmit={handleLogin}>
                        <CImage className='login-logo' fluid src={Logo} />
                        <div>
                            <CFormInput
                                className={`login-input ${!email.trim() ? 'empty' : ''}`}
                                type="email"
                                placeholder={loginText.form.emailPlaceholder}
                                value={email}
                                onChange={handleEmailChange}
                                aria-label="email"
                                style={{ color: 'white' }}
                            />
                        </div>
                        <div className='d-flex flex-column align-items-center justify-content-center '>
                            <CImage className='background-top' src={BackgroundTop} />
                            <CImage className='background-bottom' src={BackgroundBottom} />
                            <CFormInput
                                className={`login-input ${!password.trim() ? 'empty' : ''}`}
                                type={passwordVisible ? "text" : "password"}
                                placeholder={loginText.form.passwordPlaceholder}
                                value={password}
                                onChange={handlePasswordChange}
                                aria-label="password"
                                style={{ color: 'white' }}
                            />
                            <CImage
                                className='show-pass-icon'
                                src={passwordVisible ? eyeOpen : eyeClose}
                                onClick={togglePasswordVisibility}
                            />
                        </div>
                        <CButton className='btn-solidarity' type="submit" disabled={loading}>
                            {loading ? <CSpinner size="sm" /> : loginText.form.submit}
                        </CButton>
                    </CForm>
                </CContainer>
            </div>

        </div>
    );
};

export default Login;
