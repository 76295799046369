// components/top-menu/TopMenu.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CButton } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useSnapshot } from 'valtio';
import store from '../../state/store';
import './TopMenu.css';

interface MenuItem {
  text: string;
  href: string;
}

interface Menu {
  submenu: MenuItem[];
}

interface TopMenuProps {
  menu: Menu;
}

const TopMenu: React.FC<TopMenuProps> = ({ menu }) => {
  const navigate = useNavigate();
  const HeaderNav = useSnapshot(store.headerNav);
  const { goBack, navArray } = HeaderNav;

  const goToPreviousPage = () => {
    goBack();
    if (navArray.length > 1) {
      const previousHref = navArray[navArray.length - 2].href;
      navigate(previousHref);
    }
  };

  const isActive = (href: string) => {
    const currentHref = navArray[navArray.length - 1].href;
    return currentHref === href;
  };

  return (
    <div className="top-menu">
      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex justify-content-start">
          <CButton className="go-back-btn" onClick={goToPreviousPage}>
            <FontAwesomeIcon className="back-nav" icon={faChevronLeft} />
          </CButton>
        </div>
        <div className="d-flex justify-content-end">
          {menu && menu.submenu && menu.submenu.map(item => (
            <CButton
              key={item.text}
              className={`top-menu-btn ${isActive(item.href) ? 'is-active' : ''}`}
              onClick={() => navigate(item.href)}
            >
              {item.text}
            </CButton>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
