import { proxy } from 'valtio';

type FormType = 'user' | 'integration' | 'userEdit' | 'notification' | 'alerts' | 'agents' | '';

interface UserForm {
    email: string;
    password: string;
    isAdmin: boolean;
}

interface IntegrationForm {
    integrationName: string;
    integrationDetails: string;
}

interface FormState {
    display: boolean;
    formType: FormType;
    user: UserForm;
    integration: IntegrationForm;
    setUser: (email: string, password: string, isAdmin:boolean) => void;
    setIntegration: (name: string, details: string) => void;
    setFormType: (type: FormType) => void;
    setFormDisplay: (bol: boolean) => void;
}

const formState: FormState = proxy<FormState>({
    display: false,
    formType: 'user', 
    user: {
        email: '',
        password: '',
        isAdmin: false,
    },
    integration: {
        integrationName: '',
        integrationDetails: '',
    },
    setFormDisplay(bol: boolean) {
        formState.display = bol;
    },
    setUser(email: string, password: string, isAdmin:boolean) {
        formState.user.email = email;
        formState.user.password = password;
        formState.user.isAdmin = isAdmin;
    },
    setIntegration(name: string, details: string) {
        formState.integration.integrationName = name;
        formState.integration.integrationDetails = details;
    },
    setFormType(type: FormType) {
        formState.formType = type;
    }
});

export default formState;
