import { proxy } from 'valtio';
import AgentService from '../../services/agent';
import listViewStore from '../list/listView';

interface Agent {
    id: string;
    name: string;
    enabled: boolean;
    ip: string;
    group: string;
    os: string;
    version: string;
    last_activity: string;
    last_logged_in_user: string;
    actions: string;
}

interface AgentsState {
    agents: Agent[];
    loading: boolean;
    error: string | null;
    loadAgents: (token:string) => Promise<void>;
    updateAgentEnabledState: (token:string, agentId: string, enabled: boolean) => Promise<void>;
}

const agentsState = proxy<AgentsState>({
    agents: [],
    loading: false,
    error: null,
    async loadAgents(token:string) {
        this.loading = true;
        this.error = null;
        try {
            const response = await AgentService.fetchAgents(token);
            const agents = response.body.agents;
            this.agents = agents;
            listViewStore.setListData(agents);
            listViewStore.setVisibleColumns([
                { key: 'name', label: 'Name' },
                { key: 'enabled', label: 'Enabled' },
                { key: 'ip', label: 'IP' },
                { key: 'group', label: 'Group' },
                { key: 'os', label: 'OS' },
                { key: 'version', label: 'Version' },
                { key: 'last_activity', label: 'Last Activity' },
                { key: 'last_logged_in_user', label: 'Last Logged In User' },
                { key: 'actions', label: 'Actions' }
            ]);
        } catch (error) {
            if (error instanceof Error) {
                this.error = error.message;
            } else {
                this.error = String(error);
            }
        } finally {
            this.loading = false;
        }
    },
    async updateAgentEnabledState(token:string, agentId: string, enabled: boolean) {
        try {
            console.log(`Updating agent ${agentId} to ${enabled ? 'enabled' : 'disabled'}`);
            await AgentService.updateAgentEnabledState(token,agentId, enabled);
            const updatedAgentIndex = this.agents.findIndex(agent => agent.id === agentId);
            if (updatedAgentIndex !== -1) {
                this.agents[updatedAgentIndex].enabled = enabled;
                listViewStore.setListData(this.agents);  // Update the list view store data
            }
        } catch (error) {
            console.error('Error updating agent state:', error);
        }
    }
});

export default agentsState;
