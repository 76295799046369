import React, { useCallback, useState, useEffect } from 'react';
import './RowDetails.css';
import store from '../../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useSnapshot } from 'valtio';
import { CButton } from '@coreui/react';
import { handlePasswordReset } from '../table-component/tableActions';
import useSwalPopups from '../../hooks/useSwal';
import { formatDate } from '../table-component/tableActions';


interface RowDetailsProps {
    item: Record<string, any>;
    onClose: () => void;
}

const RowDetails: React.FC<RowDetailsProps> = ({ item, onClose }) => {
    const { resetPassword } = useSwalPopups();

    const ListView = store.listView;
    const FormState = useSnapshot(store.formState);
    const { formType, setFormType, setFormDisplay } = FormState;
    const { setSearchValue, listData, setListData } = ListView;
    const [localItem, setLocalItem] = useState(item);
    const dateColumns = ['timestamp', 'requestCreationTime','usercreatedate', 'userlastmodifieddate'];

    useEffect(() => {
        setLocalItem(item);
    }, [item]);

    const entries = Object.entries(localItem).filter(([key]) => key.toLowerCase() !== 'username');
    const columnCount = 4;
    const rowCount = Math.ceil(entries.length / columnCount);
    const columns = Array.from({ length: columnCount }, (_, columnIndex) =>
        entries.slice(columnIndex * rowCount, (columnIndex + 1) * rowCount)
    );

    const sendToSearch = useCallback((value: any) => {
        setSearchValue(value);
    }, [setSearchValue]);

    const handleReset = (email: string): void => {
        resetPassword().then((result) => {
            if (result) {
                handlePasswordReset(email);
            }         
        });        
    }

    return (
        <div className="row-details">
            <div className='d-flex p-2 justify-content-between align-items-center mb-2 details-title'>
                <label>Details</label>
                <CButton className="close-button" onClick={onClose}>
                    <FontAwesomeIcon className='close-icon' icon={faXmark} />
                </CButton>
            </div>
            <div className='pruebadetail'>
                <div className="details-content">
                    <div className="columns">
                        {columns.map((column, index) => (
                            <div key={index} className="column">
                                {column.map(([key, value]) => {
                                    const valueClass = typeof value === 'boolean'
                                        ? value ? 'true-value' : 'false-value'
                                        : '';

                                        const displayValue = value === null || value === undefined || value === ''
                                        ? '-' 
                                        : typeof value === 'boolean'
                                            ? String(value)
                                            : dateColumns.includes(key.toLowerCase()) && !isNaN(Date.parse(value))
                                                ? formatDate(value)
                                                : value;

                                    return (
                                        <div key={key} className="detail-item">
                                            <label>{key}</label>
                                            <span
                                                className={valueClass}
                                                onClick={() => { sendToSearch(value) }}
                                            >
                                                {displayValue}
                                            </span>
                                        </div>
                                    );
                                })}
                                {index === columnCount - 1 && formType === 'user' && (
                                    <div className="detail-item">
                                        <CButton
                                            className="btn-solidarity reset-password-button"
                                            size="sm"
                                            onClick={() => handleReset(item.email)}
                                        >
                                            Reset Password
                                        </CButton>
                                    </div>)}
                            </div>

                        ))}

                        {/* Cuarta columna con el botón de Reset Password */}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default RowDetails;
