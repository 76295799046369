import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import ListTable from '../../components/table-component/ListTable';
import TopControls from '../../components/top-controls/TopControls';
import Header from '../../components/header/header';
import store from '../../state/store';
import Loading from '../../components/Loading';
import PaginationControls from '../../components/table-component/PaginationControls';
import listView from '../../state/list/listView'; // Importa el estado original

interface OutletContext {
  section: string;
}

const Notifications: React.FC = () => {
  const FormState = useSnapshot(store.formState);
  const HeaderNav = useSnapshot(store.headerNav);
  const LayoutState = useSnapshot(store.layoutState);
  const context = useOutletContext<OutletContext>();
  const { token } = useSnapshot(store.sessionState)
  const { error } = useSnapshot(store.notificationState);
  const listViewSnapshot = useSnapshot(store.listView);
  const { addNav, cleanNav } = HeaderNav;
  const { setFormType } = FormState;
  const { setLine } = LayoutState;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    cleanNav();
    addNav("Notifications", "/notifications");
    setLine(true, true);
    setIsLoading(true);
    setFormType("notification");
    const fetchData = async () => {
      setIsLoading(true);
      await store.notificationState.loadNotifications();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (error) {
    return <p className="text-danger">Error fetching notifications</p>;
  }

  return (
    <div>
      {context.section === 'top' ? (
        <div>
          <Header />
          <TopControls />
        </div>
      ) : (
        <div>
          {isLoading ? <Loading marginTop={30} /> : <><PaginationControls
            currentPage={listViewSnapshot.currentPage}
            totalPages={listViewSnapshot.totalPages}
            totalRows={listViewSnapshot.listData.length}
            onNextPage={() => listView.nextPage()}
            onPreviousPage={() => listView.previousPage()} /><ListTable /></>}
        </div>
      )}
    </div>
  );
};

export default Notifications;
