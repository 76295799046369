import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import ListTable from '../../components/table-component/ListTable';
import TopControls from '../../components/top-controls/TopControls';
import Header from '../../components/header/header';
import logsState from '../../state/logs/logsState';
import store from '../../state/store';
import Loading from '../../components/Loading';
import PaginationControls from '../../components/table-component/PaginationControls';

interface OutletContext {
  section: string;
}

const Logs: React.FC = () => {
  const HeaderNav = useSnapshot(store.headerNav);
  const LayoutState = useSnapshot(store.layoutState);
  const LogsState = useSnapshot(logsState);
  const { token } = useSnapshot(store.sessionState);
  const FormState = useSnapshot(store.formState);
  const { setFormType } = FormState;
  const { setLine } = LayoutState;
  const { addNav, cleanNav } = HeaderNav;
  const context = useOutletContext<OutletContext>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    cleanNav();
    addNav('logs', '/logs');
    setLine(false, false);
    setFormType('');
  }, [cleanNav, addNav, setLine]);

  const handleSearchSubmit = async (parsedQuery: any) => {
    const { query } = parsedQuery;
    if (!token) {
      console.error("No authorization token found");
      return;
    }
    setIsLoading(true);
    try {
      await logsState.loadLogs(token, query, logsState.page_size);
    } catch (error) {
      console.error('Error searching logs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {context.section === 'top' ? (
        <div className='h-100'>
          <Header />
          <TopControls onSearchSubmit={handleSearchSubmit} />
        </div>
      ) : (
        <div>
          {LogsState.loading || isLoading ? (
            <Loading marginTop={30} />
          ) : (
            <>
              <PaginationControls
                currentPage={LogsState.currentPage}
                totalPages={LogsState.totalPages}
                totalRows={LogsState.total_rows}
                onNextPage={() => logsState.loadNextPage(token)}
                onPreviousPage={() => logsState.loadPreviousPage(token)}
              />
              <ListTable
                listData={LogsState.displayedLogs ? [...LogsState.displayedLogs] : []} 
                visibleColumns={LogsState.response_headers?.map(header => ({ key: header, label: header })) || []}
              />

            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Logs;