import { proxy } from "valtio";
import notificationService from "../../services/notifications";
import listViewStore from "../list/listView";
import { getToken } from '../../utils/auth';
import sessionState from '../sessionState/sessionState'; 

interface Notification {
  [key: string]: any;
}

interface NotificationsState {
  notifications: Notification[];
  loading: boolean;
  error: string | null;
  loadNotifications: () => Promise<void>;
  addNotification: (notificationData: Notification) => Promise<void>;
  updateNotification: (id: string, notificationData: Notification) => Promise<void>;
  deleteNotification: (id: string) => Promise<void>;
  loadNotificationById: (id: string) => Promise<void>;
}

const notificationsState = proxy<NotificationsState>({
  notifications: [],
  loading: false,
  error: null,

  // Load all notifications
  async loadNotifications() {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    this.loading = true;
    this.error = null;
    try {
      const response = await notificationService.fetchNotifications(token);
      const notifications = response.body.notifications;
      this.notifications = notifications;

      const columns = notifications.length > 0 ? Object.keys(notifications[0]).map((key) => ({
              key: key,
              label: key.toUpperCase()
            })) : [];

      listViewStore.setListData(notifications);
      listViewStore.setVisibleColumns(columns);
    } catch (error) {
      if (error instanceof Error) {
        this.error = error.message;
      } else {
        this.error = String(error);
      }
    } finally {
      this.loading = false;
    }
  },

  // Add a new notification
  async addNotification(notificationData: Notification) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    this.loading = true;
    this.error = null;
    try {
      await notificationService.createNotification(token, notificationData);
      await this.loadNotifications(); // Reload notifications after adding
    } catch (error) {
      if (error instanceof Error) {
        this.error = error.message;
      } else {
        this.error = String(error);
      }
    } finally {
      this.loading = false;
    }
  },

  // Update an existing notification
  async updateNotification(id: string, notificationData: Notification) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    this.loading = true;
    this.error = null;
    try {
      await notificationService.updateNotification(token, id, notificationData);
      await this.loadNotifications(); // Reload notifications after updating
    } catch (error) {
      if (error instanceof Error) {
        this.error = error.message;
      } else {
        this.error = String(error);
      }
    } finally {
      this.loading = false;
    }
  },

  // Delete a notification
  async deleteNotification(id: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    this.loading = true;
    this.error = null;
    try {
      await notificationService.deleteNotification(token, id);
      await this.loadNotifications(); // Reload notifications after deletion
    } catch (error) {
      if (error instanceof Error) {
        this.error = error.message;
      } else {
        this.error = String(error);
      }
    } finally {
      this.loading = false;
    }
  },

  // Load a single notification by ID
  async loadNotificationById(id: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    this.loading = true;
    this.error = null;
    try {
      const response = await notificationService.fetchNotificationById(token, id);
      const notification = response.body.notification;

      listViewStore.setListData([notification]); 
      const columns = Object.keys(notification).map((key) => ({
        key: key,
        label: key.toUpperCase(),
      }));
      listViewStore.setVisibleColumns(columns);
    } catch (error) {
      if (error instanceof Error) {
        this.error = error.message;
      } else {
        this.error = String(error);
      }
    } finally {
      this.loading = false;
    }
  },
});

export default notificationsState;
