import config from '../config/configDev';

const urlapi = config.urlAPI;

interface Notification {
  [key: string]: any;
}

// GET: Fetch all notifications
const fetchNotifications = async (token: string): Promise<{ body: { notifications: Notification[] } }> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/notifications`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    if (!response.ok) {
      if (response.status === 500) {
        throw new Error('Internal server error');
      } else {
        throw new Error('Failed to fetch notifications');
      }
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    throw error;
  }
};

// POST: Create a new notification
const createNotification = async (token: string, notificationData: Notification): Promise<{ body: { notification: Notification } }> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/notifications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(notificationData),
    });
    if (!response.ok) {
      if (response.status === 500) {
        throw new Error('Internal server error');
      } else {
        throw new Error('Failed to create notification');
      }
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error;
  }
};

// GET: Fetch a notification by ID
const fetchNotificationById = async (token: string, id: string): Promise<{ body: { notification: Notification } }> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/notifications/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('Notification not found');
      } else if (response.status === 500) {
        throw new Error('Internal server error');
      } else {
        throw new Error('Failed to fetch notification');
      }
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching notification with ID ${id}:`, error);
    throw error;
  }
};

// PUT: Update a notification by ID
const updateNotification = async (token: string, id: string, notificationData: Notification): Promise<{ body: { notification: Notification } }> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/notifications/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(notificationData),
    });
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('Notification not found');
      } else if (response.status === 500) {
        throw new Error('Internal server error');
      } else {
        throw new Error('Failed to update notification');
      }
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error updating notification with ID ${id}:`, error);
    throw error;
  }
};

// DELETE: Delete a notification by ID
const deleteNotification = async (token: string, id: string): Promise<void> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/notifications/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('Notification not found');
      } else if (response.status === 500) {
        throw new Error('Internal server error');
      } else {
        throw new Error('Failed to delete notification');
      }
    }
  } catch (error) {
    console.error(`Error deleting notification with ID ${id}:`, error);
    throw error;
  }
};

const notificationService = {
  fetchNotifications,
  createNotification,
  fetchNotificationById,
  updateNotification,
  deleteNotification,
};

export default notificationService;
