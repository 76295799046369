import { proxy, snapshot } from 'valtio';
import { searchLogs, searchLogsNextPage, getReportFromExecutionId } from '../../services/logs';
import listViewStore from '../list/listView';
import tabState from '../tabs/tabState';
import pako from 'pako';  // Ensure you have pako library installed

const downloadCsvFile = (fileContent: string, filename: string = 'data.csv') => {
  try {
    const binaryString = atob(fileContent);
    const binaryLength = binaryString.length;
    const bytes = new Uint8Array(binaryLength);
    for (let i = 0; i < binaryLength; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const decompressedData = pako.ungzip(bytes, { to: 'string' });
    const blob = new Blob([decompressedData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading CSV file:', error);
  }
};

interface Log {
  [key: string]: any;
}

interface LogsState {
  logs: Log[];
  originalLogs: Log[];
  loading: boolean;
  error: string | null;
  executionId: string | null;
  displayedLogs: Log[] | null;
  response_headers: string[] | null;
  currentPage: number;
  totalPages: number;
  total_rows: number;
  page_size: number;
  pageTokens: { [key: number]: string | null };  // Map page number to tokens
  loadedPages: { [key: number]: Log[] };  // Store logs for each page
  exportFullQuery: (token: string) => void;
  loadLogs: (token: string, query: string, pageSize: number) => Promise<void>;
  loadNextPage: (token: string) => Promise<void>;
  loadPreviousPage: (token: string) => Promise<void>;
  setLogsFromTab: (
    logs: Log[],
    visibleColumns: { key: string; label: string }[],
    pageTokens?: { [key: number]: string | null },
    loadedPages?: { [key: number]: Log[] }
  ) => void;
  clearFilter: () => void;
  clearLogs: () => void;
}

const logsState = proxy<LogsState>({
  logs: [],
  originalLogs: [],
  displayedLogs: [],
  loading: false,
  error: null,
  executionId: null,
  response_headers: null,
  page_size: 1000,
  currentPage: 1,
  totalPages: 1,
  total_rows: 0,
  pageTokens: {},  // Store page tokens
  loadedPages: {},  // Store logs for each page

  // Load initial logs and store the first executionId and token
  async loadLogs(token: string, query: string, pageSize: number) {
    this.loading = true;
    this.error = null;
    this.pageTokens = {};
    this.loadedPages = {};
  
    try {
      const response = await searchLogs(token, query);
  
      if (response.statusCode !== 200) {
        console.error('Error response:', response);
        throw new Error('Error fetching logs');
      }
  
      const logs = response.body.records;
      this.logs = logs;
      this.originalLogs = logs;
      this.executionId = response.body.execution_id;
  
      // Store the first page token
      this.pageTokens[1] = response.body.next_token || null;
      this.total_rows = response.body.total_rows;
  
      this.loadedPages[1] = logs; // Cache logs for the first page
  
      // Calculate total pages
      this.totalPages = Math.max(Math.ceil(this.total_rows / this.page_size), 1);
  
      if (logs.length > 0) {
        this.response_headers = response.body.headers; // Set headers from the response
      }
  
      this.currentPage = 1;
      this.displayedLogs = logs.slice(0, pageSize);
      listViewStore.setListData(this.displayedLogs);
  
      // Update the active tab with total records, page size, and current page
      const activeTab = tabState.tabs.find(tab => tab.id === tabState.activeTabId);
      if (activeTab) {
        tabState.setRecordsForTab(activeTab.id, this.total_rows);   
        tabState.setPageSizeForTab(activeTab.id, this.page_size);  
        tabState.setCurrentPageForTab(activeTab.id, this.currentPage);
        activeTab.executionId = this.executionId;  
      }
  
    } catch (error) {
      this.error = error instanceof Error ? error.message : String(error);
      console.error('Error in loadLogs:', error);
      return Promise.reject(error);
    } finally {
      this.loading = false;
    }
  },

  // Load the next page, only call API if no logs for the next page
  async loadNextPage(token: string) {
    if (!this.executionId) {
      console.log('Missing executionId, cannot load more pages');
      return;
    }
  
    // Retrieve the next token for the current page
    const nextToken = this.pageTokens[this.currentPage];
    
    if (!nextToken) {
      console.log('No nextToken available for the next page');
      return;
    }
  
    // Check if cached logs for the next page already exist
    if (this.loadedPages[this.currentPage + 1]) {
      this.currentPage += 1; 
      this.displayedLogs = this.loadedPages[this.currentPage]; 
      listViewStore.setListData(this.displayedLogs);
  
      // Update the active tab's current page in tabState
      const activeTab = tabState.tabs.find(tab => tab.id === tabState.activeTabId);
      if (activeTab) {
        tabState.setCurrentPageForTab(activeTab.id, this.currentPage);
      }
      return;
    }
  
    // If no cached logs, proceed with fetching the next page from API
    this.loading = true;
    try {
      const headersSnapshot = this.response_headers ? [...snapshot(this.response_headers)] : [];
  
      // Fetch next page using the next token
      const response = await searchLogsNextPage(token, this.executionId, nextToken, headersSnapshot);
  
      if (response.statusCode !== 200 || !response.body) {
        throw new Error('Error fetching next page of logs');
      }
  
      const logs = response.body.records;
      const newNextToken = response.body.next_token || null; 
  
      // Cache the new logs and the token for the next page
      if (logs && logs.length > 0) {
        this.logs = [...this.logs, ...logs]; 
        this.pageTokens[this.currentPage + 1] = newNextToken; 
        this.loadedPages[this.currentPage + 1] = logs; 
  
        // Increment the page after successful fetch
        this.currentPage += 1;
        this.displayedLogs = this.loadedPages[this.currentPage]; 
  
        listViewStore.setListData(this.displayedLogs); 
  
        // Update the active tab's current page in tabState
        const activeTab = tabState.tabs.find(tab => tab.id === tabState.activeTabId);
        if (activeTab) {
          tabState.setCurrentPageForTab(activeTab.id, this.currentPage);
        }
      } else {
        console.log('No logs returned for this page');
      }
    } catch (error) {
      this.error = error instanceof Error ? error.message : String(error);
    } finally {
      this.loading = false;
    }
  },

  // Load the previous page using stored tokens or cached logs
  async loadPreviousPage(token: string) {
    if (this.currentPage <= 1) {
      console.log('Already on the first page, cannot load previous page');
      return;
    }

    // If previous page logs are already loaded, use them
    if (this.loadedPages[this.currentPage - 1]) {
      this.currentPage -= 1;
      this.displayedLogs = this.loadedPages[this.currentPage];
      listViewStore.setListData(this.displayedLogs);

      // Update the active tab's current page in tabState
      const activeTab = tabState.tabs.find(tab => tab.id === tabState.activeTabId);
      if (activeTab) {
        tabState.setCurrentPageForTab(activeTab.id, this.currentPage);
      }

      return;
    }

    this.loading = true;
    try {
      const previousToken = this.pageTokens[this.currentPage - 1];
      const headers = this.response_headers ? [...snapshot(this.response_headers)] : [];
      const response = await searchLogsNextPage(token, this.executionId as string, previousToken as string, headers);

      if (response.statusCode !== 200 || !response.body) {
        throw new Error('Error fetching previous page of logs');
      }

      const logs = response.body.records;
      this.loadedPages[this.currentPage - 1] = logs;  // Store logs for previous page
      this.currentPage -= 1;
      this.displayedLogs = logs.slice(0, this.page_size);

      listViewStore.setListData(this.displayedLogs);

      // Update the active tab's current page in tabState
      const activeTab = tabState.tabs.find(tab => tab.id === tabState.activeTabId);
      if (activeTab) {
        tabState.setCurrentPageForTab(activeTab.id, this.currentPage);
      }
    } catch (error) {
      this.error = error instanceof Error ? error.message : String(error);
    } finally {
      this.loading = false;
    }
  },

  setLogsFromTab(
    logs: Log[],
    visibleColumns: { key: string; label: string }[],
    pageTokens?: { [key: number]: string | null },
    loadedPages?: { [key: number]: Log[] }
) {
    this.logs = logs;
    listViewStore.setListData(logs);
    listViewStore.setVisibleColumns(visibleColumns);

    const activeTab = tabState.tabs.find(tab => tab.id === tabState.activeTabId);
    console.log('Setting logs for tab:', activeTab);
    console.log('Active Tab State:', activeTab?.records, activeTab?.pageSize, activeTab?.currentPage);

    if (activeTab) {
        tabState.setRecordsForTab(activeTab.id, activeTab.records);
        tabState.setPageSizeForTab(activeTab.id, activeTab.pageSize);
        tabState.setCurrentPageForTab(activeTab.id, activeTab.currentPage);

        this.total_rows = activeTab.records;
        this.page_size = activeTab.pageSize;
        this.currentPage = activeTab.currentPage;
        this.executionId = activeTab.executionId;
        this.totalPages = Math.ceil(this.total_rows / this.page_size);

        // Restore pageTokens and loadedPages if provided
        if (pageTokens) {
            this.pageTokens = pageTokens;   // This ensures nextToken is available after switching tabs
        }

        if (loadedPages) {
            this.loadedPages = loadedPages;
        }
    }
},

  // Export logs to CSV using executionId
  async exportFullQuery(token: string) {
    try {
      const activeTab = tabState.tabs.find(tab => tab.id === tabState.activeTabId);

      if (!activeTab || !activeTab.executionId) {
        throw new Error('No active execution ID found.');
      }

      const executionId = activeTab.executionId;
      const response = await getReportFromExecutionId(token, executionId);

      if (response.statusCode !== 200 || !response.body) {
        throw new Error('Error fetching logs for export');
      }

      const body = typeof response.body === 'string' ? JSON.parse(response.body) : response.body;

      if (body.file_content) {
        downloadCsvFile(body.file_content);
      } else {
        console.error('No file_content found in the response');
      }
    } catch (error) {
      console.error('Error exporting full query:', error);
    }
  },

  clearLogs() {

    // Clear only the displayed logs and related properties, but ensure originalLogs is preserved
    this.logs = [];
    this.displayedLogs = [];
    this.executionId = null;
    this.response_headers = null;
    this.pageTokens = {};
    this.loadedPages = {};
    this.total_rows = 0;        // Reset total records
    this.page_size = 1000;      // Reset page size to default
    this.currentPage = 1;
    this.totalPages = 1;        // Reset total pages to 1
    tabState.setLoadingForTab(1, false)

    // Clear the listView UI, but preserve original logs
    listViewStore.setListData([]);
    listViewStore.setVisibleColumns([]);

  },

  clearFilter() {
    listViewStore.setListData(this.originalLogs);
  },
});

export default logsState;
