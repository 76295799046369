import config from '../config/configDev';

const urlapi = config.urlAPI;

interface Alert {
  [key: string]: any;
}

const fetchAlerts = async (token: string): Promise<{ body: { alerts: Alert[] } }> => {
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/alerts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    if (!response.ok) {
      if (response.status === 500) {
        throw new Error('Internal server error');
      } else {
        throw new Error('Failed to fetch alerts');
      }
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching alerts:', error);
    throw error;
  }
};

const alertService = { fetchAlerts };

export default alertService;
