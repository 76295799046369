import React, { ChangeEvent, useState, useEffect } from 'react';
import Select from 'react-select';
import { CFormSelect, CButton, CFormInput } from '@coreui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSQLBuilder } from '../../hooks/useSQLBuilder';
import './SQLBuilderModal.css';  // The CSS file we created for styling

interface SQLBuilderModalProps {
  isVisible: boolean;
  onClose: () => void;
  onQueryChange: (query: string) => void;
}

interface AggregateFunction {
  func: string;
  column: string;
  alias: string;
  useDistinctInCount?: boolean;  // New property to track DISTINCT for COUNT
}

const SQLBuilderModal: React.FC<SQLBuilderModalProps> = ({ isVisible, onClose, onQueryChange }) => {
  const {
    sqlQuery,
    selectedTable,
    setSelectedTable,
    availableColumns,
    selectedColumns,
    setSelectedColumns,
    clauses,
    setClauses,
    aliases,
    setAliases,
    isQueryValid,
    clauseTypes,
    sqlOperators,
    orderByOptions,
    aggregateFunctions,
    tables,
    timestampCondition,  // Timestamp condition from hook
    setTimestampCondition,
    timestampValue1,  // First timestamp value
    setTimestampValue1,
    timestampValue2,  // Second timestamp value for "between"
    setTimestampValue2,
    useDistinct,        // Access global useDistinct state
    setUseDistinct,      // Access setter for global useDistinct
    selectedLogicalOperators,  // Logical operators
    setSelectedLogicalOperators, // Setters for logical operators
    logicalOperators,
    limit,
    setLimit
  } = useSQLBuilder();  // Call the hook to access SQL logic

  const [showAggregateSection, setShowAggregateSection] = useState(false);
  const [showTimestampSection, setShowTimestampSection] = useState(false);
  const [aggregates, setAggregates] = useState<AggregateFunction[]>([]);  // Store aggregates

  // Function to confirm the query and close the modal
  const handleUseQuery = () => {
    onQueryChange(sqlQuery);  // Pass query back to the parent
    onClose();  // Close the modal after the query is used
  };

  // Function to reset all query states
  const handleResetQuery = () => {
    setSelectedTable('');
    setSelectedColumns(['*']);
    setClauses([]);
    setAggregates([]);  // Reset aggregates
    setAliases({});
    setTimestampCondition('');
    setTimestampValue1('');
    setTimestampValue2('');
    setUseDistinct(false);  // Reset DISTINCT
    setSelectedLogicalOperators([]);
  };

  // Function to add a new aggregate function
  const addAggregate = () => {
    setAggregates([...aggregates, { func: '', column: '', alias: '', useDistinctInCount: false }]);  // Include the distinct option for COUNT
  };

  // Function to handle changes in aggregate function, column, alias, or DISTINCT for COUNT
  const handleAggregateChange = (index: number, key: keyof AggregateFunction, value: string | boolean) => {
    const updatedAggregates = aggregates.map((agg, i) =>
      i === index ? { ...agg, [key]: value } : agg
    );
    setAggregates(updatedAggregates);
  };

  // Function to remove an aggregate function
  const removeAggregate = (index: number) => {
    setAggregates(aggregates.filter((_, i) => i !== index));
  };

  // Effect to handle aggregate functions in SQL query
  useEffect(() => {
    if (aggregates.length > 0) {
      const aggregateFuncs = aggregates.map(agg => {
        const aliasPart = agg.alias ? ` AS ${agg.alias}` : '';
        const distinctPart = agg.func === 'COUNT' && agg.useDistinctInCount ? 'DISTINCT ' : '';  // Add DISTINCT inside COUNT if enabled
        return `${agg.func}(${distinctPart}${agg.column})${aliasPart}`;
      });
      setSelectedColumns(aggregateFuncs);
    } else {
      setSelectedColumns(['*']);
    }
  }, [aggregates]);

  const handleLogicalOperatorChange = (index: number, value: string) => {
    const updatedOperators = [...selectedLogicalOperators];
    updatedOperators[index] = value;
    setSelectedLogicalOperators(updatedOperators);
  };

  return (
    <div className={`modal ${isVisible ? 'show' : ''}`} style={{ display: isVisible ? 'flex' : 'none' }} role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">SQL Query Builder</h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              aria-label="Close"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <textarea
              rows={4}
              style={{
                width: '100%',
                resize: 'vertical',
              }}
              value={sqlQuery}
              readOnly
              className="custom-textarea"
            />
            <div className="mt-4">
              <CFormSelect
                id="tableSelect"
                value={selectedTable}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedTable(e.target.value as keyof typeof tables)}
                className="cformselect"
              >
                <option value="">Select Table</option>
                {Object.keys(tables).map((table) => (
                  <option key={table} value={table}>
                    {table}
                  </option>
                ))}
              </CFormSelect>
            </div>

            {/* Multi-select using react-select */}
            <Select
              isMulti
              options={availableColumns.map((column) => ({ label: column, value: column }))}
              onChange={(columns) => setSelectedColumns(columns ? columns.map(c => c.value) : [])}
              placeholder="Select Columns"
              defaultValue={{ label: '*', value: '*' }}
              classNamePrefix="cformselect"
            />

            {/* Dynamic Clauses Section */}
            {clauses.map((clause, index) => (
              <div key={index} className="mt-2 d-flex align-items-center mb-2 clause-row">
                <CFormSelect
                  value={clause.type}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => setClauses(
                    clauses.map((c, i) => i === index ? { ...c, type: e.target.value } : c)
                  )}
                  className="mt-3 me-2 cformselect"
                  style={{ width: '25%' }}
                >
                  <option value="">Select Clause</option>
                  {clauseTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </CFormSelect>

                {clause.type !== 'LIMIT' && (
                  <CFormSelect
                    value={clause.column}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => setClauses(
                      clauses.map((c, i) => i === index ? { ...c, column: e.target.value } : c)
                    )}
                    className="mt-3 me-2 cformselect"
                    style={{ width: '25%' }}
                  >
                    <option value="">Select Column</option>
                    {availableColumns.map((column) => (
                      <option key={column} value={column}>
                        {column}
                      </option>
                    ))}
                  </CFormSelect>
                )}

                {(clause.type === 'WHERE' || clause.type === 'HAVING') && (
                  <>
                    <CFormSelect
                      value={clause.operator}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => setClauses(
                        clauses.map((c, i) => i === index ? { ...c, operator: e.target.value } : c)
                      )}
                      className="mt-3 me-2 cformselect"
                      style={{ width: '15%' }}
                    >
                      <option value="">Select Operator</option>
                      {sqlOperators.map((op) => (
                        <option key={op} value={op}>
                          {op}
                        </option>
                      ))}
                    </CFormSelect>

                    <CFormInput
                      placeholder="Value"
                      value={clause.value}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setClauses(
                        clauses.map((c, i) => i === index ? { ...c, value: e.target.value } : c)
                      )}
                      className="mt-3 me-2 cforminput"
                      style={{ width: '15%', backgroundColor: '#142A33' }}
                    />
                  </>
                )}

                {index < clauses.length - 1 && (
                  <CFormSelect
                    value={selectedLogicalOperators[index] || 'AND'}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => handleLogicalOperatorChange(index, e.target.value)}
                    className="mt-3 me-2 cformselect"
                    style={{ width: '10%' }}
                  >
                    {logicalOperators.map((operator) => (
                      <option key={operator} value={operator}>
                        {operator}
                      </option>
                    ))}
                  </CFormSelect>
                )}


                {clause.type === 'ORDER BY' && (
                  <CFormSelect
                    value={clause.orderByDirection || ''}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => setClauses(
                      clauses.map((c, i) => i === index ? { ...c, orderByDirection: e.target.value } : c)
                    )}
                    className="mt-3 me-2 cformselect"
                    style={{ width: '15%' }}
                  >
                    <option value="">Select Order</option>
                    {orderByOptions.map((direction) => (
                      <option key={direction} value={direction}>
                        {direction}
                      </option>
                    ))}
                  </CFormSelect>
                )}

                {clause.type === 'LIMIT' && (
                  <div className="mt-3">
                    <CFormInput
                      id="limitInput"
                      placeholder="Number of rows"
                      value={clause.value || ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newLimit = e.target.value;
                        setClauses(
                          clauses.map((c, i) => i === index ? { ...c, value: newLimit } : c)
                        );
                      }}
                      className="me-2 cformselect"
                      style={{ backgroundColor: '#142A33' }}
                    />
                  </div>
                )}

                <CButton
                  onClick={() => setClauses(clauses.filter((_, i) => i !== index))}
                  className="cbutton"
                  style={{
                    width: '45px',
                    height: '45px',
                    padding: '0',
                    fontSize: '1.5rem',
                    marginTop: '0.4rem'  // Align with input fields
                  }}
                >
                  -
                </CButton>
              </div>
            ))}
            <CButton
              onClick={() => setClauses([...clauses, { type: '', column: '' }])}
              className="mt-2 cbutton"
              style={{ width: '100%' }}
            >
              Add Clause
            </CButton>



            {/* Add DISTINCT/UNIQUE Toggle Button */}
            <CButton
              onClick={() => setUseDistinct(!useDistinct)}  // Toggle the global DISTINCT state
              className="mt-2 cbutton"
              style={{ width: '100%' }}
            >
              {useDistinct ? 'DISTINCT Enabled' : 'Enable DISTINCT'}
            </CButton>


            <CButton
              style={{ width: '100%' }}
              onClick={() => setShowTimestampSection(!showTimestampSection)}
              className="mt-2 cbutton"
            >
              {showTimestampSection ? 'Hide Date/Time' : 'Add Date/Time'}
            </CButton>

            {/* Timestamp picker section */}
            {showTimestampSection && (
              <div className="mt-4">
                <CFormSelect
                  value={timestampCondition}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => setTimestampCondition(e.target.value)}
                  className="cformselect"
                >
                  <option value="">Select Condition</option>
                  <option value=">">Greater than</option>
                  <option value="<">Less than</option>
                  <option value="BETWEEN">Between</option>
                </CFormSelect>

                {/* Date/Time Pickers */}
                {timestampCondition && (
                  <>
                    <div className="timestamp-container">
                      <DatePicker
                        selected={timestampValue1 ? new Date(timestampValue1) : null}
                        onChange={(date: Date) => setTimestampValue1(date.toISOString())}
                        showTimeSelect
                        dateFormat="Pp"
                        className="date-picker"
                        placeholderText="Select Date"
                      />
                    </div>

                    {timestampCondition === 'BETWEEN' && (
                      <div className="mt-2 timestamp-container">
                        <DatePicker
                          selected={timestampValue2 ? new Date(timestampValue2) : null}
                          onChange={(date: Date) => setTimestampValue2(date.toISOString())}
                          showTimeSelect
                          dateFormat="Pp"
                          className="date-picker"
                          placeholderText="Select Date"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            )}


            <div className="mt-2">

              <CButton
                onClick={addAggregate}
                className="cbutton"
                style={{ width: '100%' }}
              >
                Add Aggregate
              </CButton>
              {aggregates.map((aggregate, index) => (
                <div key={index} className="mt-2 d-flex align-items-center mb-2">
                  {/* Aggregate Function Selection */}
                  <CFormSelect
                    value={aggregate.func}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      handleAggregateChange(index, 'func', e.target.value)
                    }
                    className="mt-3 me-2 cformselect"
                    style={{ width: '25%' }}
                  >
                    <option value="">Select Aggregate</option>
                    {aggregateFunctions.map((func) => (
                      <option key={func} value={func}>
                        {func}
                      </option>
                    ))}
                  </CFormSelect>

                  {/* Display DISTINCT checkbox for COUNT */}
                  {aggregate.func === 'COUNT' && (
                    <div className="distinct-checkbox ms-1 mt-3 me-2" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        className="distinct-checkbox-input"
                        checked={aggregate.useDistinctInCount || false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleAggregateChange(index, 'useDistinctInCount', e.target.checked)
                        }
                        style={{ marginRight: '8px', transform: 'scale(1.2)' }}
                      />
                      <span >DISTINCT</span>
                    </div>
                  )}

                  {/* Column Selection */}
                  <CFormSelect
                    value={aggregate.column}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      handleAggregateChange(index, 'column', e.target.value)
                    }
                    className="mt-3 me-2 cformselect"
                    style={{ width: '25%' }}
                  >
                    <option value="">Select Column</option>
                    {availableColumns.map((column) => (
                      <option key={column} value={column}>
                        {column}
                      </option>
                    ))}
                  </CFormSelect>

                  {/* Alias Input */}
                  <CFormInput
                    placeholder="Alias"
                    value={aggregate.alias}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleAggregateChange(index, 'alias', e.target.value)
                    }
                    className="mt-3 me-2 cforminput"
                    style={{ width: '25%' }}
                  />

                  {/* Remove Button */}
                  <CButton
                    onClick={() => removeAggregate(index)}
                    className="cbutton"
                    style={{
                      width: '45px',
                      height: '45px',
                      padding: '0',
                      fontSize: '1.5rem',
                      marginTop: '0.4rem'
                    }}
                  >
                    -
                  </CButton>
                </div>
              ))}
            </div>
          </div>

          {/* Add the Reset Query button here */}
          <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CButton
              onClick={handleResetQuery}
              className="cforminput"
              style={{ marginRight: 'auto' }}  // Aligns to the left
            >
              RESET QUERY
            </CButton>

            <CButton
              onClick={handleUseQuery}
              className="cforminput"
              style={{ marginLeft: 'auto' }}  // Aligns to the right
            >
              USE QUERY
            </CButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SQLBuilderModal;
