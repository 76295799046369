import { useState, useEffect } from 'react';
import {
  generateSQLQuery,
  validateSqlQuery,
  getTablesAndColumns,
  getAggregateFunctions,
  getClauseTypes,
  getSqlOperators,
  getOrderByOptions,
  getLogicalOperators
} from '../utils/sqlUtils';

interface Clause {
  type: string;
  column: string;
  operator?: string;
  value?: string;
  logicalOperator?: string;
  orderByDirection?: string;
}

export const useSQLBuilder = () => {
  const [sqlQuery, setSqlQuery] = useState<string>('');
  const [selectedTable, setSelectedTable] = useState<keyof typeof tables | ''>('');
  const [selectedColumns, setSelectedColumns] = useState<string[]>(['*']);
  const [availableColumns, setAvailableColumns] = useState<string[]>([]);
  const [clauses, setClauses] = useState<Clause[]>([]);
  const [selectedAggregateFunctions, setSelectedAggregateFunctions] = useState<string[]>([]);
  const [selectedLogicalOperators, setSelectedLogicalOperators] = useState<string[]>([]);
  const [aliases, setAliases] = useState<Record<string, string>>({});
  const [isQueryValid, setIsQueryValid] = useState<boolean>(false);
  const [timestampCondition, setTimestampCondition] = useState<string>('');  // New state for timestamp condition
  const [timestampValue1, setTimestampValue1] = useState<string>('');        // New state for first timestamp value
  const [timestampValue2, setTimestampValue2] = useState<string>('');        // New state for second timestamp value (for BETWEEN)
  const [limit, setLimit] = useState<string>(''); // New state for limit
  
  // New state for DISTINCT/UNIQUE clause
  const [useDistinct, setUseDistinct] = useState<boolean>(false);

  // Fetch SQL-related data from utils
  const tables = getTablesAndColumns();
  const aggregateFunctions = getAggregateFunctions();
  const clauseTypes = getClauseTypes();
  const sqlOperators = getSqlOperators();
  const orderByOptions = getOrderByOptions();
  const logicalOperators = getLogicalOperators();

  useEffect(() => {
    if (selectedTable) {
      setAvailableColumns(tables[selectedTable]);
    } else {
      setAvailableColumns([]);
    }
  }, [selectedTable]);

  useEffect(() => {
    const query = generateSQLQuery(
      selectedTable, 
      selectedColumns, 
      selectedAggregateFunctions,
      selectedLogicalOperators, 
      aliases, 
      clauses, 
      timestampCondition,  // Pass timestamp condition
      timestampValue1,      // Pass first timestamp value
      timestampValue2,      // Pass second timestamp value (if BETWEEN)
      useDistinct           // Pass the DISTINCT flag
    );
    setSqlQuery(query);
  }, [selectedTable, selectedColumns, clauses, selectedAggregateFunctions, selectedLogicalOperators, aliases, timestampCondition, timestampValue1, timestampValue2, useDistinct]);  // Add useDistinct as a dependency

  useEffect(() => {
    const validationResult = validateSqlQuery(sqlQuery);
    setIsQueryValid(validationResult === null);
  }, [sqlQuery]);

  return {
    sqlQuery,
    selectedTable,
    setSelectedTable,
    availableColumns,
    selectedColumns,
    setSelectedColumns,
    clauses,
    setClauses,
    selectedAggregateFunctions,
    setSelectedAggregateFunctions,
    setSelectedLogicalOperators,
    selectedLogicalOperators,
    logicalOperators,
    aliases,
    setAliases,
    isQueryValid,
    clauseTypes,
    sqlOperators,
    orderByOptions,
    aggregateFunctions,
    tables,
    timestampCondition,
    setTimestampCondition,
    timestampValue1,
    setTimestampValue1,
    timestampValue2,
    setTimestampValue2,
    useDistinct,          // Expose the useDistinct flag
    setUseDistinct,       // Expose the setter for useDistinct
    limit,
    setLimit,
  };
};
