export const getToken = (token:string, expiration:string, clearSession: () => void): string | null => {
    //const token = localStorage.getItem('token');
    //const expiration = localStorage.getItem('tokenExpiration');
    //const { token, expiration, clearSession } = useSnapshot(store.sessionState);

    if (!token || !expiration || Date.now() > parseInt(expiration, 10)) {
        clearSession()
        //localStorage.removeItem('token');
        //localStorage.removeItem('tokenExpiration');
        return null;
    }

    return token;
};
