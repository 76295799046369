import { proxy } from 'valtio';

interface Column {
    key: string;
    label: string;
}

interface ListView {
    listData: any[];
    filteredListData: any[];
    searchValue: string;
    visibleColumns: Column[];
    currentPage: number;
    totalPages: number;
    page_size: number;
    setListData: (data: any[]) => void;
    setSearchValue: (value: string) => void;
    setVisibleColumns: (value: Column[]) => void;
    loadPage: (page: number) => void;
    nextPage: () => void;
    previousPage: () => void;
    generateFileName: (extension: string) => string;
    exportCSV: (array: any[]) => void;
    exportJSON: (array: any[]) => void;
    downloadFile: (dataString: string, extension: string) => void;
}


const listView: ListView = proxy({
    listData: [] as any[],  
    filteredListData: [] as any[],  
    searchValue: '',
    visibleColumns: [],
    currentPage: 1,
    totalPages: 1,
    page_size: 1000,
    setListData(data) {
        this.listData = data;
        this.filteredListData = data.slice(0, this.page_size);
        this.totalPages = Math.ceil(data.length / this.page_size);
    },
    setSearchValue(value) {
        listView.searchValue = value;
        if (value === '') {
            listView.filteredListData = listView.listData.slice(0, listView.page_size);
        } else {
            listView.filteredListData = listView.listData.filter(log =>
                Object.values(log).some(field =>
                    field?.toString().toLowerCase().includes(value.toString().toLowerCase())
                )
            );
        }
    },
    loadPage(page) {
        this.currentPage = page;
        const startIndex = (page - 1) * this.page_size;
        const endIndex = startIndex + this.page_size;
        this.filteredListData = this.listData.slice(startIndex, endIndex);
    },
    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.loadPage(this.currentPage + 1);
        }
    },
    previousPage() {
        if (this.currentPage > 1) {
            this.loadPage(this.currentPage - 1);
        }
    },
    setVisibleColumns(value) {
        listView.visibleColumns = value;
    },
    generateFileName(extension) {
        const now = new Date();
        const pad = (num: number) => String(num).padStart(2, '0');
        return `data_${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}_${pad(now.getHours())}-${pad(now.getMinutes())}-${pad(now.getSeconds())}.${extension}`;
    },
    exportCSV(array) {
        if (array.length === 0) {
            console.error('The array is empty.');
            return;
        }
        const headers = Object.keys(array[0]);
        const csvRows = [
            headers.join(','), 
            ...array.map(obj => headers.map(header => JSON.stringify(obj[header] || '')).join(','))
        ];
        listView.downloadFile(csvRows.join('\n'), 'csv');
    },
    exportJSON(array) {
        listView.downloadFile(JSON.stringify(array, null, 2), 'json');
    },
    downloadFile(dataString, extension) {
        const blob = new Blob([dataString], { type: extension === 'csv' ? 'text/csv' : 'application/json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = listView.generateFileName(extension);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
});

export default listView;
