import { RawMessage } from "aws-sdk/clients/ses";
import config from "../config/configDev";
import sessionState from "../state/sessionState/sessionState";

const urlapi = config.urlAPI;

const logout = (): void => {
    // Clear session state
    sessionState.clearSession(); 

    // Clear localStorage or any other persistent storage used
    localStorage.clear();

    // Redirect to root page
    window.location.href = "/";
};

interface User {
    Username: string;
    email: string | null;
    email_verified: boolean | null;
    UserCreateDate: string;
    UserLastModifiedDate: string;
    Enabled: boolean;
    UserStatus: string;
}

interface Group {
    id: string;
    name: string;
}

const login = async (
    email: string,
    password: string
): Promise<{ success: boolean; message: any }> => {
    try {
        const response = await fetch(`${urlapi}/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return { success: true, message: data };
    } catch (error) {
        return {
            success: false,
            message: error instanceof Error ? error.message : String(error),
        };
    }
};

const createHeaders = (): HeadersInit => {
    return {
        "Content-Type": "application/json",
        Authorization: sessionState.token
    };
};

const register = async (email: string, password: string, admin : boolean): Promise<void> => {    
    try {
        const response = await fetch(`${urlapi}/auth/registration`, {
            method: "POST",
            headers: createHeaders(),
            body: JSON.stringify({ email, password }),
        });
        if (!response.ok) {
            throw new Error("Failed to register user");
        }
        return response.json();
    } catch (error) {
        console.error("Error during registration:", error);
        throw error;
    }
};

const confirmRegistration = async (email: string,confirmationCode: string): Promise<any> => {
    try {
        const response = await fetch(`${urlapi}/auth/confirm-registration`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, confirmation_code: confirmationCode }),
        });
        return response.json();
    } catch (error) {
        console.error("Error during registration confirmation:", error);
        throw error;
    }
};

const fetchUsers = async (token: string): Promise<any> => {
    try {
        const response = await fetch(`${urlapi}/auth/users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        if (!response.ok) {
            throw new Error("Failed to fetch users");
        }
        return response.json();
    } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
    }
};

const whoami = async (token: string): Promise<any> => {
    try {
        const response = await fetch(`${urlapi}/auth/users/whoami`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        if (!response.ok) {
            throw new Error("Failed to fetch user info");
        }
        return response.json();
    } catch (error) {
        console.error("Error fetching user info:", error);
        throw error;
    }
};

const fetchUserGroups = async (
    token: string,
    email: string
): Promise<Group[]> => {
    try {
        const response = await fetch(`${urlapi}/auth/users/groups`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({ email }),
        });
        if (!response.ok) {
            throw new Error("Failed to fetch user groups");
        }
        return response.json();
    } catch (error) {
        console.error("Error fetching user groups:", error);
        throw error;
    }
};

const fetchGroups = async (token: string): Promise<Group[]> => {
    try {
        const response = await fetch(`${urlapi}/auth/groups`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        if (!response.ok) {
            throw new Error("Failed to fetch groups");
        }
        return response.json();
    } catch (error) {
        console.error("Error fetching groups:", error);
        throw error;
    }
};

const changePassword = async (
    token: string,
    oldPassword: string,
    newPassword: string
): Promise<void> => {
    try {
        const response = await fetch(`${urlapi}/auth/users/change-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                old_password: oldPassword,
                new_password: newPassword,
            }),
        });
        if (!response.ok) {
            throw new Error("Failed to change password");
        }
    } catch (error) {
        console.error("Error changing password:", error);
        throw error;
    }
};

const disableUser = async (token: string, email: string): Promise<void> => {
    try {
        const response = await fetch(`${urlapi}/auth/admin/disable-user`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({ email }),
        });
        if (!response.ok) {
            throw new Error("Failed to disable user");
        } else {
            console.log("User disabled correctly")
        }
    } catch (error) {
        console.error("Error disabling user:", error);
        throw error;
    }
};

const deleteUser = async (token: string, email: string): Promise<void> => {
    try {
        const response = await fetch(`${urlapi}/auth/admin/delete-user`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({ email }),
        });
        if (!response.ok) {
            throw new Error("Failed to delete user");
        }
    } catch (error) {
        console.error("Error deleting user:", error);
        throw error;
    }
};

const enableUser = async (token: string, email: string): Promise<void> => {
    try {
        const response = await fetch(`${urlapi}/auth/admin/enable-user`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({ email }),
        });
        if (!response.ok) {
            throw new Error("Failed to enable user");
        } else {
            console.log("User enabled correctly")
        }
    } catch (error) {
        console.error("Error enabling user:", error);
        throw error;
    }
};

const resetUserPassword = async (
    token: string,
    email: string
): Promise<void> => {
    try {
        const response = await fetch(`${urlapi}/auth/admin/reset-user-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({ email }),
        });
        if (!response.ok) {
            throw new Error("Failed to reset user password");
        }
        return response.json();
    } catch (error) {
        console.error("Error resetting user password:", error);
        throw error;
    }
};

const userService = {
    login,
    register,
    confirmRegistration,
    fetchUsers,
    whoami,
    fetchUserGroups,
    fetchGroups,
    changePassword,
    disableUser,
    deleteUser,
    enableUser,
    resetUserPassword,
    logout
};

export default userService;
