import React from 'react';
import { CButton } from '@coreui/react';
import { useSnapshot } from 'valtio';
import store from '../../state/store';

interface CustomButtonProps {
    onClick: () => void;
}

const NewFormButton: React.FC<CustomButtonProps> = ({ onClick }) => {
    const FormState = useSnapshot(store.formState);
    const { formType } = FormState;

    const getButtonText = (formType: string) => {
        switch (formType) {
            case 'user':
                return 'New User';
            case 'userEdit':
                return 'Edit User';
            case 'integration':
                return 'New Integration';
            case 'notification':
                return 'New Notification';
            default:
                return '';
        }
    };

    return (
        <CButton className='table-new-integration' onClick={onClick}>
            {getButtonText(formType)}
        </CButton>
    );
};

export default NewFormButton;
