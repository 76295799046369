import React from 'react';
import { useSnapshot } from 'valtio';
import store from '../../state/store';
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
import './Header.css'

const Header = () => {
    const HeaderNav = useSnapshot(store.headerNav);

    return (        
            <div className="modules-header">
                <CBreadcrumb >
                    {HeaderNav.navArray.map((item, index) => (
                        <CBreadcrumbItem
                            key={index}
                            href={item.href}
                            active={index === HeaderNav.navArray.length - 1}
                        >
                            {item.tag.toUpperCase()}
                        </CBreadcrumbItem>
                    ))}
                </CBreadcrumb>                
                <label>SOLIDARITYLABS.IO</label>
            </div>        
    );
}

export default Header;
