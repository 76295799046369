import React from 'react';
import { CFormInput, CFormSelect } from '@coreui/react';

interface NotificationFormProps {
    notificationName: string;
    setNotificationName: (notificationName: string) => void;
    notificationType: string;
    setNotificationType: (notificationType: string) => void;
    channel: string;
    setChannel: (channel: string) => void;
    webhookURL: string;
    setWebhookURL: (webhookURL: string) => void;
    errorMessage: string;
}

const NotificationForm: React.FC<NotificationFormProps> = ({
    notificationName,
    setNotificationName,
    notificationType,
    setNotificationType,
    channel,
    setChannel,
    webhookURL,
    setWebhookURL,
    errorMessage,
}) => {
    
    const notificationTypes = ['Slack', 'Discord', 'Telegram', 'Whatsapp'];

    return (
        <>
            <label className='flex-form-input'>
                Name
                <div className="input-container">
                    <CFormInput
                        type="text"
                        placeholder="Notification Name"
                        value={notificationName}
                        onChange={(e:any) => setNotificationName(e.target.value)}
                        aria-label="Notification Name"
                    />
                </div>
            </label>

            <label className='flex-form-input'>
                Type
                <div className="input-container flex-form-dropdown">
                    <CFormSelect
                        className="flex-form-dropdown"
                        value={notificationType}
                        onChange={(e:any) => setNotificationType(e.target.value)}
                        aria-label="Notification Type"
                    >
                        <option value="">Notification Type</option>
                        {notificationTypes.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </CFormSelect>
                </div>
            </label>

            <label className='flex-form-input'>
                Channel
                <div className="input-container">
                    <CFormInput
                        type="text"
                        placeholder="Channel"
                        value={channel}
                        onChange={(e:any) => setChannel(e.target.value)}
                        aria-label="Channel"
                    />
                </div>
            </label>

            <label className='flex-form-input'>
                Webhook URL
                <div className="input-container">
                    <CFormInput
                        type="text"
                        placeholder="Webhook URL"
                        value={webhookURL}
                        onChange={(e:any) => setWebhookURL(e.target.value)}
                        aria-label="Webhook URL"
                    />
                </div>
            </label>

            {errorMessage && <p className='error-message shake'>{errorMessage}</p>}

        </>
    );
};

export default NotificationForm;
