import React from 'react';
import { CImage } from '@coreui/react';
import './notFound.css';
import Text from '../../locales/en.json';
import icon from '../../assets/img/404-icon.png';
import bar from '../../assets/img/404-bar.png';

const NotFound: React.FC = () => (
  <div className=''>
    <div className='not-found-container'>
      <div className=''>
        <div>
          <CImage className='not-found-icon' fluid src={icon} />
        </div>
        <div className='not-found-box'>
          <label className='not-found-code'>404</label>
          <label className='not-found-message'>{Text.components['not-found'].text}</label>
          <CImage className='not-found-bar' src={bar} />
        </div>
      </div>
    </div>
    <div className='not-found-footer'>
      <label>SOLIDARITYLABS.IO/</label>
    </div>
  </div>
);

export default NotFound;
