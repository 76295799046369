import store from "../../state/store";
import { toast } from 'sonner';


interface Log {
  [key: string]: any;
}

export const toggleEnabled = (log: Log, formType: string) => {
  const { usersState, integrationState, notificationState } = store;

  switch (formType) {
    case "integration":
      if (log.enabled || log.Enabled) {
        integrationState.disableIntegration(log.integration_id);
      } else {
        integrationState.enableIntegration(log.integration_id);
      }
      break;
    case "user":
      if (log.enabled || log.Enabled) {
        usersState.disableUser(log.email);
      } else {
        usersState.enableUser(log.email);
      }
      break;
    case "notification":
      if (log.enabled || log.Enabled) {
        //notificationState.disableNotification(log.notification_id);
      } else {
        //notificationState.enableNotification(log.notification_id);
      }
      break;
    default:
      console.error("Tipo de formulario no soportado:", formType);
      break;
  }
};

export const deleteRow = (log: Log, formType: string) => {
  const { usersState, notificationState, integrationState } = store;
  console.log(
    `Eliminando fila : ${JSON.stringify(log)} para el formType: ${formType}`
  );
      switch (formType) {
        case "integration":
          integrationState.deleteIntegration(log.integration_id);
          break;
        case "user":
          usersState.deleteUser(log.email);
          break;
        case "notification":
          notificationState.deleteNotification(log.notification_id);
          break;
        default:
          console.error("Tipo de formulario no soportado:", formType);
      }
};

export const matchLogItem = (item: any, log: Log, formType: string) => {
  if (formType === "integration" && item.integration_id && log.integration_id) {
    return item.integration_id === log.integration_id;
  } else if (formType === "user" && item.Username && log.Username) {
    return item.Username === log.Username;
  } else if (
    formType === "notification" &&
    item.notification_id &&
    log.notification_id
  ) {
    return item.notification_id === log.notification_id;
  } else if (formType === "agents" && item.agent_id && log.agent_id) {
    return item.agent_id === log.agent_id;
  }
  return false;
};

export const handlePasswordReset = async (user: any) => {
  const { usersState } = store;
  const response = await usersState.resetUserPassword(user);
  if (response.statusCode === 200) {
    toast.success('Password reset successfully');
  } else {
    toast.error('Password reset failed');
  }
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};
