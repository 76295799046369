import { proxy } from "valtio";
import alertService from "../../services/alerts";
import listViewStore from "../list/listView";

interface Alert {
  [key: string]: any;
}

interface AlertsState {
  alerts: Alert[];
  loading: boolean;
  error: string | null;
  loadAlerts: (token: string) => Promise<void>;
}

const alertsState = proxy<AlertsState>({
  alerts: [],
  loading: false,
  error: null,
  async loadAlerts(token: string) {
    this.loading = true;
    this.error = null;
    try {
      const response = await alertService.fetchAlerts(token);
      const alerts = response.body.alerts;

      // Map objects or display key-values if it's an object
      const processedAlerts = alerts.map((alert: any) => {
        const processedAlert: any = {};
        Object.keys(alert).forEach(key => {
          if (typeof alert[key] === 'object') {
            // If it's an object, you can choose to stringify or pick specific fields
            processedAlert[key] = JSON.stringify(alert[key], null, 2);
          } else {
            processedAlert[key] = alert[key];
          }
        });
        return processedAlert;
      });

      this.alerts = processedAlerts;

      const columns = alerts.length > 0 ? Object.keys(alerts[0]).map((key) => ({
              key: key,
              label: key.toUpperCase()
            })) : [];

      listViewStore.setListData(processedAlerts);
      listViewStore.setVisibleColumns(columns);
    } catch (error) {
      if (error instanceof Error) {
        this.error = error.message;
      } else {
        this.error = String(error);
      }
    } finally {
      this.loading = false;
    }
  },
});

export default alertsState;
