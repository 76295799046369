import { proxy } from 'valtio';

interface MenuState {
    isOpen: boolean;
    toggleMenu: () => void;
}

const menuState: MenuState = proxy({
    isOpen: false,
    toggleMenu() {
        menuState.isOpen = !menuState.isOpen;
    }
});

export default menuState;
