import React from 'react';
import './MainSection.css'
import store from '../../state/store';
import { useSnapshot } from 'valtio';


const MainSection = ({ topContent, bottomContent }) => {
    const LayoutState = useSnapshot(store.layoutState)
    const MenuState = useSnapshot(store.menuState)
    const { isOpen } = MenuState 
    const { hasLine, isDashed } = LayoutState

    return (
        <div className="main-sections">
            <div className={`top-section ${isDashed ? 'is-dashed' : hasLine ? 'has-line' : ''}`}>
                {topContent}
            </div>
            <div className={`bottom-section ${!isOpen ? '' : 'expanded'}`}>
                {bottomContent}
            </div>
        </div>
    );
};

export default MainSection;