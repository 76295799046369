import { useCallback } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import store from "../state/store";
import { text } from "stream/consumers";

const useSwalPopups = () => {
  const navigate = useNavigate();

  const showPopup = useCallback((content: string, title?: string) => {
    Swal.fire({
      title: title || "Notification",
      html: content,
      icon: "info",
      confirmButtonText: "OK",
    });
  }, []);

  const showError = useCallback((content: string, title?: string) => {
    Swal.fire({
      icon: "error",
      title: title || "Error",
      text: content,
    });
  }, []);

  const showWarning = useCallback((content: string, title?: string) => {
    Swal.fire({
      icon: "warning",
      title: title || "Oops...",
      text: content,
    });
  }, []);

  const showSessionExpired = useCallback(() => {
    let timerInterval: NodeJS.Timeout | undefined;
    Swal.fire({
      title: "Session Expired",
      html: "You will be redirected in <b></b> seconds",
      icon: "warning",
      timer: 5000,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getHtmlContainer()?.querySelector("b");
        if (timer) {
          timerInterval = setInterval(() => {
            timer.textContent = `${Math.ceil(Swal.getTimerLeft()! / 1000)}`;
          }, 100);
        }
      },
      willClose: () => {
        clearInterval(timerInterval);
        navigate("/login");
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("Popup closed by the timer");
      }
    });
  }, [navigate]);

  const showConfirm = useCallback(async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action is irreversible!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });
    return result.isConfirmed;
  }, []);

  const resetPassword = useCallback(async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action is irreversible!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, reset it!",
      cancelButtonText: "Cancel",
    });
    return result.isConfirmed;
  }, []);

  const confirRegistrarion = useCallback(async (email:string) => {
    const { usersState } = store;
    Swal.fire({
      title: "Account not activated",
      text: "Please check your email for the activation code",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Confirm",
      showLoaderOnConfirm: true,
      preConfirm: async (code) => {
        try {  
          const response = await usersState.confirmRegistration(email,code);
          return response;
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((response) => {
      if (response.value.statusCode === 401) {
        Swal.fire({
          title: `Confirmation error`,
          text: response.value.body.message
        });
      } else {
        Swal.fire({
          title: `Confirmation success`,
          text: 'you can login now'
        });
      }
    });
  }, []);

  return {
    showPopup,
    showError,
    showSessionExpired,
    showWarning,
    showConfirm,
    confirRegistrarion,
    resetPassword,
  };
};

export default useSwalPopups;
