import { proxy } from 'valtio';

interface LayoutState {
    hasLine: boolean;
    isDashed: boolean;
    setLine: (bol: boolean, dashed: boolean) => void;
}

const layoutState: LayoutState = proxy({
    hasLine: false,
    isDashed: false,
    setLine(bol, dashed) {
        layoutState.hasLine = bol;
        layoutState.isDashed = bol && dashed;
    }
});

export default layoutState;
