import agents from '../assets/mocks/agents.json'
// import logs from '../assets/mocks/logs.json'

export const agentsActivity = () => {
    return agents
}

// export const logsService = () => {
//     return new Promise((resolve) => {
//         setTimeout(() => {
//             resolve(logs);
//         }, 1000);
//     });
// }

const login = async (email: string, password: string): Promise<{ success: boolean; message: any }> => {
  try {
    const response = await fetch('https://v9k23qrbr9.execute-api.sa-east-1.amazonaws.com/dev/api/v1/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    if (!response.ok) {
      throw new Error('Error en la solicitud de inicio de sesión');
    }
    const data = await response.json();
    return { success: true, message: data };
  } catch (error) {
    return { success: false, message: (error instanceof Error) ? error.message : String(error) };
  }
};

export { login };
  