// src/reducers/formReducer.ts
export const initialState = {
  email: "",
  password: "",
  confirmPassword: "",
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
  passwordVisible: false,
  isAdmin: false,
  logName: "",
  integrationType: "",
  authType: "",
  logType: "",
  integrationData: "",
  notificationName: "",
  notificationType: "",
  channel: "",
  webhookURL: "",
  errorMessage: "",
  isFormMinimized: false,
  authProfile: "",
  bucketName: "",
  bucketPrefix: "",
};

interface FormState {
  email: string;
  password: string;
  confirmPassword: string;
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  passwordVisible: boolean;
  isAdmin: boolean;
  logName: string;
  integrationType: string;
  authType: string;
  logType: string;
  integrationData: string;
  notificationName: string;
  notificationType: string;
  channel: string;
  webhookURL: string;
  errorMessage: string;
  isFormMinimized: boolean;
  authProfile: string;
  bucketName: string;
  bucketPrefix: string;
}

interface SetFieldAction {
  type: "SET_FIELD";
  field: keyof FormState;
  value: string | boolean;
}

interface TogglePasswordVisibilityAction {
  type: "TOGGLE_PASSWORD_VISIBILITY";
}

interface ToggleFormMinimizeAction {
  type: "TOGGLE_FORM_MINIMIZE";
}

interface SetErrorAction {
  type: "SET_ERROR";
  message: string;
}

interface ResetFormAction {
  type: "RESET_FORM";
}

type FormAction =
  | SetFieldAction
  | TogglePasswordVisibilityAction
  | ToggleFormMinimizeAction
  | SetErrorAction
  | ResetFormAction;

export const formReducer = (
  state: FormState,
  action: FormAction
): FormState => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "TOGGLE_PASSWORD_VISIBILITY":
      return {
        ...state,
        passwordVisible: !state.passwordVisible,
      };
    case "TOGGLE_FORM_MINIMIZE":
      return {
        ...state,
        isFormMinimized: !state.isFormMinimized,
      };
    case "SET_ERROR":
      return {
        ...state,
        errorMessage: action.message,
      };
    case "RESET_FORM":
      return initialState;
    default:
      return state;
  }
};
