import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import ListTable from '../../components/table-component/ListTable';
import TopControls from '../../components/top-controls/TopControls';
import Header from '../../components/header/header';
import store from '../../state/store';
import Loading from '../../components/Loading';
import listView from '../../state/list/listView'; // Importa el estado original
import PaginationControls from '../../components/table-component/PaginationControls';

interface OutletContext {
  section: string;
}

const Integrations: React.FC = () => {
  const context = useOutletContext<OutletContext>();
  const HeaderNav = useSnapshot(store.headerNav);
  const LayoutState = useSnapshot(store.layoutState);
  const FormState = useSnapshot(store.formState);
  const { token } = useSnapshot(store.sessionState)
  const { error } = useSnapshot(store.integrationState);
  const { addNav, cleanNav } = HeaderNav;
  const { setFormType } = FormState;
  const { setLine } = LayoutState;
  const [isLoading, setIsLoading] = useState(true);
  const listViewSnapshot = useSnapshot(store.listView);

  useEffect(() => {
    cleanNav();
    addNav("Integrations", "/integrations");
    setLine(true, true);
    setIsLoading(true);
    setFormType("integration");
    const fetchData = async () => {
      setIsLoading(true);
      await store.integrationState.loadIntegrations();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (error) {
    return <p className="text-danger">Error fetching integrations</p>;
  }

  return (
    <div>
      {context.section === 'top' ? (
        <div>
          <Header />
          <TopControls />
        </div>
      ) : (
        <div>
          {isLoading ? <Loading marginTop={30} /> : <><PaginationControls
            currentPage={listViewSnapshot.currentPage}
            totalPages={listViewSnapshot.totalPages}
            totalRows={listViewSnapshot.listData.length}
            onNextPage={() => listView.nextPage()}
            onPreviousPage={() => listView.previousPage()} /><ListTable /></>}
        </div>
      )}
    </div>
  );
};

export default Integrations;
