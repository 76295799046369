import { proxy } from "valtio";
import integrationService from "../../services/integrations";
import listViewStore from "../list/listView";
import { getToken } from '../../utils/auth';
import sessionState from '../sessionState/sessionState'; 



interface Integration {
  [key: string]: any;
}

interface IntegrationsState {
  integrations: Integration[];
  loading: boolean;
  error: string | null;
  loadIntegrations: () => Promise<void>;
  addIntegration: (integrationData: Integration) => Promise<any>;
  updateIntegration: (id: string, integrationData: Integration) => Promise<void>;
  deleteIntegration: (id: string) => Promise<void>;
  loadIntegrationById: (id: string) => Promise<void>;
  enableIntegration: (id: string) => Promise<void>;
  disableIntegration: (id: string) => Promise<void>;
}

const integrationsState = proxy<IntegrationsState>({
  integrations: [],
  loading: false,
  error: null,

  // Load all integrations
  async loadIntegrations() {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    integrationsState.loading = true;
    integrationsState.error = null;
    try {
      const response = await integrationService.fetchIntegrations(token);
      const integrations = response.body.integrations;
      integrationsState.integrations = integrations;

      const columns = integrations.length > 0 ? Object.keys(integrations[0]).map((key) => ({
              key: key,
              label: key.toUpperCase()
            })) : [];

      listViewStore.setListData(integrations);
      listViewStore.setVisibleColumns(columns);
    } catch (error) {
      if (error instanceof Error) {
        integrationsState.error = error.message;
      } else {
        integrationsState.error = String(error);
      }
    } finally {
      integrationsState.loading = false;
    }
  },

  // Add a new integration
  async addIntegration(integrationData: Integration) {
    integrationsState.loading = true;
    integrationsState.error = null;
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    
    try {
      const response = await integrationService.createIntegration(token, integrationData);
      console.log(response);
      await integrationsState.loadIntegrations(); // Reload integrations after adding
      return response
    } catch (error) {
      if (error instanceof Error) {
        integrationsState.error = error.message;
      } else {
        integrationsState.error = String(error);
      }
      throw error;
    } finally {
      integrationsState.loading = false;
    }
  },

  // Update an existing integration
  async updateIntegration(id: string, integrationData: Integration) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    integrationsState.loading = true;
    integrationsState.error = null;
    try {
      await integrationService.updateIntegration(token, id, integrationData);
      await integrationsState.loadIntegrations(); // Reload integrations after updating
    } catch (error) {
      if (error instanceof Error) {
        integrationsState.error = error.message;
      } else {
        integrationsState.error = String(error);
      }
    } finally {
      integrationsState.loading = false;
    }
  },

  // Delete an integration
  async deleteIntegration(id: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    integrationsState.loading = true;
    integrationsState.error = null;
    try {
      await integrationService.deleteIntegration(token, id);
      await integrationsState.loadIntegrations(); // Reload integrations after deletion
    } catch (error) {
      if (error instanceof Error) {
        integrationsState.error = error.message;
      } else {
        integrationsState.error = String(error);
      }
    } finally {
      integrationsState.loading = false;
    }
  },

  // Load a single integration by ID
  async loadIntegrationById(id: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    integrationsState.loading = true;
    integrationsState.error = null;
    try {
      const response = await integrationService.fetchIntegrationById(token, id);
      const integration = response.body.integration;

      // Assuming you would want to update listViewStore based on a single integration
      listViewStore.setListData([integration]); 
      const columns = Object.keys(integration).map((key) => ({
        key: key,
        label: key.toUpperCase(),
      }));
      listViewStore.setVisibleColumns(columns);
    } catch (error) {
      if (error instanceof Error) {
        integrationsState.error = error.message;
      } else {
        integrationsState.error = String(error);
      }
    } finally {
      integrationsState.loading = false;
    }
  },

  async enableIntegration(id: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    integrationsState.loading = true;
    integrationsState.error = null;
    try {
      await integrationService.updateIntegration(token, id, { enabled: true });
      await integrationsState.loadIntegrations(); // Recargar integraciones tras actualizar
    } catch (error) {
      if (error instanceof Error) {
        integrationsState.error = error.message;
      } else {
        integrationsState.error = String(error);
      }
    } finally {
      integrationsState.loading = false;
    }
  },
  
  // Deshabilitar integración
  async disableIntegration(id: string) {
    const token = getToken(sessionState.token, sessionState.expiration, sessionState.clearSession);
    if (!token) return;
    integrationsState.loading = true;
    integrationsState.error = null;
    try {
      await integrationService.updateIntegration(token, id, { enabled: false });
      await integrationsState.loadIntegrations(); // Recargar integraciones tras actualizar
    } catch (error) {
      if (error instanceof Error) {
        integrationsState.error = error.message;
      } else {
        integrationsState.error = String(error);
      }
    } finally {
      integrationsState.loading = false;
    }
  },




});

export default integrationsState;
