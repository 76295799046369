import { proxy, subscribe } from 'valtio';
import CryptoJS from 'crypto-js';

const SECRET_KEY = 'djgmsk#mu(rzbp&hdp'; // Cambiar esto por una clave segura

interface SessionState {
    token: string;
    accessToken: string;
    expiration: string;
    clearSession: () => void;
    saveSession: (token: string, accessToken: string, expiration:number) => void;
}

const encryptData = (data: string): string => {
    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
};

const decryptData = (encryptedData: string): string => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch {
        localStorage.removeItem('token');
        localStorage.removeItem('accToken');
        localStorage.removeItem('expiration');
        return '';
    }
};

const initializeState = () => {
    const savedIdToken = localStorage.getItem('token');
    const savedaccToken = localStorage.getItem('accToken');
    const savedExpiration = localStorage.getItem('expiration');
    if (savedIdToken && savedaccToken && savedExpiration) {
        const expiration = parseInt(savedExpiration, 10);
        if (Date.now() > expiration) {
            localStorage.removeItem('token');
            localStorage.removeItem('accToken');
            localStorage.removeItem('expiration');
            return { token: '', accessToken: '', expiration: '' };
        }
        try {
            const token = decryptData(savedIdToken);
            const accessToken = decryptData(savedaccToken);
            return { token, accessToken, expiration: savedExpiration };
        } catch {
            localStorage.removeItem('token');
            localStorage.removeItem('accToken');
            localStorage.removeItem('expiration');
            return { token: '', accessToken: '', expiration: '' };
        }
    }
    return { token: '', accessToken: '', expiration: '' };
};

const sessionState: SessionState = proxy({
    ...initializeState(),
    clearSession() {
        sessionState.token = '';
        sessionState.accessToken = '';
        sessionState.expiration = '';
        localStorage.removeItem('token');
        localStorage.removeItem('accToken');
        localStorage.removeItem('expiration');
    },
    saveSession(token: string, accessToken: string ,expiration: number) {
        sessionState.token = token;
        sessionState.accessToken = accessToken;
        sessionState.expiration = (Date.now() + expiration * 1000).toString();
        localStorage.setItem('token', encryptData(sessionState.token));
        localStorage.setItem('accToken', encryptData(sessionState.accessToken));
        localStorage.setItem('expiration', sessionState.expiration);
    },
});

subscribe(sessionState, () => {
    if (sessionState.token) {
        localStorage.setItem('token', encryptData(sessionState.token));
    } else {
        localStorage.removeItem('token');
    }
    localStorage.setItem('expiration', sessionState.expiration);
});

export default sessionState;
