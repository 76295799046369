import React from 'react';
import { CFormInput, CFormSelect } from '@coreui/react';

interface IntegrationFormProps {
    logName: string;
    setLogName: (logName: string) => void;
    integrationType: string;
    setIntegrationType: (integrationType: string) => void;
    authType: string;
    setAuthType: (authType: string) => void;
    logType: string;
    setLogType: (logType: string) => void;
    integrationData: string;
    setIntegrationData: (integrationData: string) => void;
    authProfile: string;
    setAuthProfile: (authProfile: string) => void;
    bucketName: string;
    setBucketName: (bucketName: string) => void;
    bucketPrefix: string;
    setBucketPrefix: (bucketPrefix: string) => void;
    errorMessage: string;
}

const IntegrationForm: React.FC<IntegrationFormProps> = ({
    logName,
    setLogName,
    integrationType,
    setIntegrationType,
    authType,
    setAuthType,
    logType,
    setLogType,
    authProfile,
    setAuthProfile,
    bucketName,
    setBucketName,
    bucketPrefix,
    setBucketPrefix,
    errorMessage,
}) => {
    
    const integrationOptions = ['AWS', 'Github'];

    const getAuthOptions = (integrationType: string) => {
        switch (integrationType) {
            case 'AWS':
                return ['AWS Access Key', 'AWS Role'];
            case 'Github':
                return ['Github Token'];
            default:
                return [];
        }
    };

    const getLogOptions = (integrationType: string) => {
        switch (integrationType) {
            case 'AWS':
                return ['GuardDuty', 'Cloudtrail', 'VPC', 'WAF', 'ALB'];
            case 'Github':
                return ['Github Actions', 'Audit Logs'];
            default:
                return [];
        }
    };

    return (
        <>
            <label className='flex-form-input'>
                Log Name
                <div className="input-container">
                    <CFormInput
                        type="text"
                        placeholder="Log Name"
                        value={logName}
                        onChange={(e: { target: { value: string; }; }) => setLogName(e.target.value)}
                        aria-label="Log Name"
                    />
                </div>
            </label>

            <label className='flex-form-input'>
                Integration Type
                <div className="input-container flex-form-dropdown">
                    <CFormSelect
                        className="flex-form-dropdown"
                        value={integrationType}
                        onChange={(e: { target: { value: string; }; }) => setIntegrationType(e.target.value)}
                        aria-label="Integration Type"
                    >
                        <option value="">Select Integration</option>
                        {integrationOptions.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </CFormSelect>
                </div>
            </label>

            <label className='flex-form-input'>
                Auth Type
                <div className="input-container flex-form-dropdown">
                    <CFormSelect
                        className="flex-form-dropdown"
                        value={authType}
                        onChange={(e: { target: { value: string; }; }) => setAuthType(e.target.value)}
                        aria-label="Auth Type"
                        disabled={!integrationType} 
                    >
                        <option value="">Select Auth Type</option>
                        {getAuthOptions(integrationType).map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </CFormSelect>
                </div>
            </label>

            <label className='flex-form-input'>
                Log Type
                <div className="input-container flex-form-dropdown">
                    <CFormSelect
                        className="flex-form-dropdown"
                        value={logType}
                        onChange={(e: { target: { value: string; }; }) => setLogType(e.target.value)}
                        aria-label="Log Type"
                        disabled={!integrationType} 
                    >
                        <option value="">Select Log Type</option>
                        {getLogOptions(integrationType).map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </CFormSelect>
                </div>
            </label>

            {/* <label className='flex-form-input'>
                {integrationType ? `${integrationType} Data` : 'Integration Data'}
                <div className="input-container">
                    <CFormInput
                        className="flex-form-dropdown"
                        type="text"
                        placeholder={integrationType ? `${integrationType} Data` : 'Integration Data'}
                        value={integrationData}
                        onChange={(e: { target: { value: string; }; }) => setIntegrationData(e.target.value)}
                        aria-label="Integration Data"
                        disabled={!integrationType} 
                    />
                </div>
            </label> */}

            {/* Campo específico para AWS: Auth Profile */}
            {integrationType === 'AWS' && (
                <label className='flex-form-input'>
                    Auth Profile
                    <div className="input-container">
                        <CFormInput
                            type="text"
                            placeholder="Auth Profile"
                            value={authProfile}
                            onChange={(e: { target: { value: string; }; }) => setAuthProfile(e.target.value)}
                            aria-label="Auth Profile"
                        />
                    </div>
                </label>
            )}

            {/* Campos específicos para AWS y authType != GuardDuty */}
            {integrationType === 'AWS' && logType !== 'GuardDuty' && (
                <>
                    <label className='flex-form-input'>
                        Bucket Name
                        <div className="input-container">
                            <CFormInput
                                type="text"
                                placeholder="Bucket Name"
                                value={bucketName}
                                onChange={(e: { target: { value: string; }; }) => setBucketName(e.target.value)}
                                aria-label="Bucket Name"
                            />
                        </div>
                    </label>

                    <label className='flex-form-input'>
                        Bucket Prefix
                        <div className="input-container">
                            <CFormInput
                                type="text"
                                placeholder="Bucket Prefix"
                                value={bucketPrefix}
                                onChange={(e: { target: { value: string; }; }) => setBucketPrefix(e.target.value)}
                                aria-label="Bucket Prefix"
                            />
                        </div>
                    </label>
                </>
            )}

            {errorMessage && <p className='error-message shake'>{errorMessage}</p>}
        </>
    );
};

export default IntegrationForm;
