import config from '../config/configDev';

const urlapi = config.urlAPI;

interface Integration {
  [key: string]: any;
}

// GET: Fetch all integrations
const fetchIntegrations = async (token: string): Promise<{ body: { integrations: Integration[] } }> => {
   
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/integrations`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    if (response.status === 401) {
       
      throw new Error('Session expired');
    }
    if (!response.ok) {
      if (response.status === 500) {
        throw new Error('Internal server error');
      } else {
        throw new Error('Failed to fetch integrations');
      }
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};

const createIntegration = async (token: string, integrationData: Integration): Promise<{ body: { integration: Integration } }> => {
   
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/integrations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(integrationData),
    });
    if (response.status === 401) {
       
      throw new Error('Session expired');
    }
    return response.json();
  } catch (error) {
    console.error('Error creating integration:', error);
    throw error;
  }
};

// GET: Fetch an integration by ID
const fetchIntegrationById = async (token: string, id: string): Promise<{ body: { integration: Integration } }> => {
   
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/integrations/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    if (response.status === 401) {
       
      throw new Error('Session expired');
    }
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('Integration not found');
      } else if (response.status === 500) {
        throw new Error('Internal server error');
      } else {
        throw new Error('Failed to fetch integration');
      }
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching integration with ID ${id}:`, error);
    throw error;
  }
};

// PUT: Update an integration by ID
const updateIntegration = async (token: string, id: string, integrationData: Integration): Promise<{ body: { integration: Integration } }> => {
   
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/integrations/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(integrationData),
    });
    if (response.status === 401) {
       
      throw new Error('Session expired');
    }
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('Integration not found');
      } else if (response.status === 500) {
        throw new Error('Internal server error');
      } else {
        throw new Error('Failed to update integration');
      }
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error updating integration with ID ${id}:`, error);
    throw error;
  }
};

// DELETE: Delete an integration by ID
const deleteIntegration = async (token: string, id: string): Promise<void> => {
   
  try {
    if (!token) {
      throw new Error('No authorization token found');
    }
    const response = await fetch(`${urlapi}/integrations/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    if (response.status === 401) {
       
      throw new Error('Session expired');
    }
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('Integration not found');
      } else if (response.status === 500) {
        throw new Error('Internal server error');
      } else {
        throw new Error('Failed to delete integration');
      }
    }
  } catch (error) {
    console.error(`Error deleting integration with ID ${id}:`, error);
    throw error;
  }
};

const integrationService = {
  fetchIntegrations,
  createIntegration,
  fetchIntegrationById,
  updateIntegration,
  deleteIntegration,
};

export default integrationService;
